import { User, onAuthStateChanged, getIdTokenResult } from 'firebase/auth';

import React, { createContext } from 'react';
import { auth } from '../config/FirebaseConfig';
import { Pathway } from '../types';
import PresenceMonitoring from './PresenceMonitoring';
import { LoadingPage } from '../pages/LoadingPage';
import CallableServices from '../services/CallableServices';
import { HttpsCallableResult } from 'firebase/functions';

export interface AppAuth {
    user: User | null;
    handleLogout?: () => Promise<void>;
    handleLogin?: (
        email: string,
        password: string,
        deviceToken?: string,
    ) => Promise<HttpsCallableResult<any>>;
    verifyOtp?: (
        otpCode: string,
        trustThisDevice?: boolean,
        deviceToken?: string,
    ) => Promise<HttpsCallableResult<{ status: number; token?: string }>>;
    role: string;
    customer_id: string;
    customer_name: string;
    pathway: Pathway;
    permissions: { [key: string]: boolean } | null;
    operational_lot: string[];
    can_impersonate?: boolean;
}

const presence = new PresenceMonitoring();

export const AuthContext = createContext<AppAuth>({
    user: null,
    role: 'guest',
    customer_id: 'EQL',
    customer_name: 'EQL',
    pathway: Pathway.PIP,
    permissions: null,
    operational_lot: [],
});

export const AuthenticationContext: React.FC = (props) => {
    const { children } = props;

    const [loading, setLoading] = React.useState(true);
    const [authUser, setAuthUser] = React.useState<User | null>(null);
    const [privileges, setPrivileges] = React.useState<{
        role: string;
        customer_id: string;
        customer_name: string;
        pathway: Pathway;
        permissions: { [key: string]: boolean };
        operational_lot: string[];
    }>({
        role: 'guest',
        customer_id: '',
        customer_name: '',
        pathway: Pathway.PIP,
        permissions: {
            wca_access: false,
            pip_access: false,
            pip_wca_access: false,
        },
        operational_lot: [],
    });

    const handleLogout = async () => {
        await CallableServices.signout();
        window.location.reload();
    };

    const handleLogin = async (
        email: string,
        password: string,
        deviceToken?: string,
    ) => {
        const res = await CallableServices.signin({
            email,
            password,
            device_token: deviceToken,
        });
        return res;
    };

    const verifyOtp = async (
        otpCode: string,
        trustThisDevice?: boolean,
        deviceToken?: string,
    ): Promise<HttpsCallableResult<{ status: number; token: string }>> => {
        const res = await CallableServices.verifyOtp(
            otpCode,
            trustThisDevice,
            deviceToken,
        );
        return res as HttpsCallableResult<{ status: number; token: string }>;
    };

    React.useEffect(() => {
        let isActive = true;
        const unsubAuth = onAuthStateChanged(auth, async (user) => {
            if (!isActive) {
                return;
            }
            setAuthUser(user);
            // Monitor presence
            presence.monitor();
            if (user) {
                const token = await getIdTokenResult(user);
                if (!token) {
                    return;
                }
                setPrivileges({
                    role: token.claims.role,
                    pathway: (token.claims.pathway as Pathway) ?? null,
                    customer_id: token.claims.customer_id ?? null,
                    customer_name: token.claims.customer_name ?? null,
                    permissions: token.claims.permissions ?? null,
                    operational_lot: token.claims.operational_lot ?? [],
                    can_impersonate: token.claims.can_impersonate ?? false,
                } as any);
            }
            setLoading(false);
        });
        return () => {
            unsubAuth();
            isActive = false;
        };
        // eslint-disable-next-line
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user: authUser,
                handleLogout,
                handleLogin,
                verifyOtp,
                ...privileges,
            }}
        >
            {loading ? <LoadingPage /> : children}
        </AuthContext.Provider>
    );
};
