/* eslint-disable no-unused-expressions */
import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';

type PdfPayload = {
    assessment_id: string;
    pathway?: 'pip' | 'wca';
};

type PdfResponse = {
    pdfAsBase64String: string;
    status: number;
    message: string;
};

export const infoShareReportPdf = async (
    payload: PdfPayload,
): Promise<HttpsCallableResult<PdfResponse>> => {
    return httpsCallable<PdfPayload, PdfResponse>(
        functions,
        'pdf-createartefact',
    )(payload);
};

export const downloadPdfReport = async (
    base64String: string,
    fileName?: string,
) => {
    const base64 = await fetch(base64String);
    const blob = await base64.blob();

    const link = document.createElement('a');
    document.body.appendChild(link);

    const url = window.URL.createObjectURL(blob);

    link.href = url;
    link.download = fileName || 'health_information_share_report.pdf';
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
};

export const openInNewWindowPdfReport = async (base64String: string) => {
    const base64 = await fetch(base64String);
    const blob = await base64.blob();
    const link = document.createElement('a');
    document.body.appendChild(link);
    const url = window.URL.createObjectURL(blob);

    window.open(url, '_blank', 'noopener,noreferrer');

    document.body.removeChild(link);
};
