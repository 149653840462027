import {
    Box,
    Button,
    createTheme,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
} from '@mui/material';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@mui/styles';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';
import React from 'react';
import {
    InternalUser,
    removeSystemUser,
} from '../../services/systemUserServices';
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Close, PersonAdd } from '@mui/icons-material';
import SystemUserForm from '../users/SystemUserForm';
import useConfirm from '../../hooks/useConfirm';

const SystemUsersList = () => {
    const [systemUsers, setSystemUsers] = React.useState<InternalUser[]>([]);
    const [newSystemUserDialogOpen, setNewSystemUserDialogOpen] =
        React.useState<boolean>(false);
    const [editSystemUser, setEditUserData] = React.useState<
        InternalUser | undefined
    >();

    const authCtx = useAuth();
    const { isConfirmed } = useConfirm();

    const { enqueueSnackbar } = useSnackbar();

    const getSystemUsers = async () => {
        const ref = collection(db, 'admin_users');
        const _query = query(ref, where('role', '==', 'system_admin'));
        const snapshot = await getDocs(_query);
        const temp: any[] = [];
        snapshot.forEach((doc) => {
            temp.push({ ...doc.data(), id: doc.id });
        });
        setSystemUsers(temp);
    };

    const onDelete = async (user: InternalUser) => {
        try {
            if (!user?.id) {
                return;
            }
            if (authCtx.user?.uid === user?.id) {
                enqueueSnackbar('You cannot delete yourself', {
                    variant: 'warning',
                });
                return;
            }
            // delete user
            await removeSystemUser(user?.id);
            // refresh list
            await getSystemUsers();
        } catch (e) {
            console.error(e);
        }
    };

    React.useEffect(() => {
        (async () => {
            await getSystemUsers();
        })();
    }, []);

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Box sx={{ mt: 4, mb: 4 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Box />
                    <Tooltip title="Add a new system user">
                        <Button
                            startIcon={<PersonAdd />}
                            variant="outlined"
                            onClick={() => {
                                setNewSystemUserDialogOpen(true);
                                setEditUserData(undefined);
                            }}
                        >
                            Add System User
                        </Button>
                    </Tooltip>
                </Stack>
            </Box>
            <ThemeProvider theme={createTheme()}>
                <MaterialTable
                    title="System Users"
                    columns={[
                        {
                            title: 'ID',
                            field: 'id',
                            editable: 'never',
                        },
                        {
                            title: 'Name',
                            field: 'name',
                        },
                        {
                            title: 'Email',
                            field: 'email',
                        },
                        {
                            title: 'Role',
                            field: 'role',
                        },
                    ]}
                    data={systemUsers}
                    options={{
                        searchFieldVariant: 'outlined',
                        pageSize: 20,
                        pageSizeOptions: [20, 40, 60],
                    }}
                    actions={[
                        (rowData) => ({
                            icon: 'edit',
                            tooltip: 'Edit Customer',
                            onClick: (event, rowData) => {
                                setNewSystemUserDialogOpen(true);
                                setEditUserData(rowData as InternalUser);
                            },
                            hidden: authCtx.user?.uid === rowData?.id,
                        }),
                        (rowData) => ({
                            tooltip: 'Remove Selected User',
                            icon: 'delete',
                            onClick: async (evt, data) => {
                                const confirm = await isConfirmed(
                                    'Are you sure you want to delete this user?',
                                );
                                if (!confirm) {
                                    return;
                                }
                                await onDelete(data as InternalUser);
                                getSystemUsers();
                            },
                            hidden: authCtx.user?.uid === rowData?.id,
                        }),
                    ]}
                />
            </ThemeProvider>
            <Dialog
                open={newSystemUserDialogOpen}
                maxWidth="md"
                fullWidth
                onClose={() => {
                    setNewSystemUserDialogOpen(false);
                }}
            >
                <DialogTitle>
                    <Stack direction="row">
                        <Box sx={{ flex: 1 }}>
                            {editSystemUser?.id
                                ? 'Update system user'
                                : 'Add system user'}
                        </Box>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    setNewSystemUserDialogOpen(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Box>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <SystemUserForm
                        data={editSystemUser}
                        onCompleted={() => {
                            setNewSystemUserDialogOpen(false);
                            getSystemUsers();
                        }}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default SystemUsersList;
