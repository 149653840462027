import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';
import DashboardLayout from './layouts/DashboardLayout';
import theme from './theme';
import { AuthenticationContext as AuthProvider } from './authentication/AuthenticationContext';
import { UserList } from './containers/users/Users';
import ConfirmContextProvider from './contexts/ConfirmContextProvider';
import ConfirmDialog from './components/ConfirmDialog';
import AuditLogs from './containers/AuditLogs';
import { useAuth } from './hooks/useAuth';
import { ResetPassword } from './containers/auth/ResetPassword';
import ForgotPassword from './containers/auth/ForgotPassword';
import CustomerList from './containers/customers/CustomerList';
import CustomerSettings from './containers/customers/CustomerSettings';
import SystemUsersList from './containers/users/SystemUserList';
import { InternalViewForUsers } from './containers/users/InternalViewForUsers';
import RoleControlled from './routes/RoleControlled';
import AuthRequired from './routes/AuthRequired';
import NamespaceControlled from './routes/NamespaceControlled';
import ReportsContainer from './containers/ReportsContainer';
import SignInPasswordAndEmail from './containers/auth/SignInPasswordAndEmail';
import MiContainer from './containers/MiContainer';
import ONodeConfigurations from './containers/customers/ONodeConfiguration';
import MessagesList from './containers/messages/MessagesList';
import SigninWithCustomTokenContainer from './containers/auth/SigninWithCustomTokenContainer';

const Main = () => {
    const authCtx = useAuth();

    return (
        <RouterProvider
            router={createBrowserRouter([
                {
                    path: '/',
                    index: true,
                    element: !authCtx.user ? (
                        <SignInPasswordAndEmail />
                    ) : (
                        <Navigate to="dashboard" replace />
                    ),
                },
                {
                    path: '/impersonate/:token?',
                    element: <SigninWithCustomTokenContainer />,
                },
                { path: '/reset-password', element: <ResetPassword /> },
                { path: '/forgot-password', element: <ForgotPassword /> },
                {
                    path: 'dashboard',
                    element: (
                        <AuthRequired>
                            <DashboardLayout />
                        </AuthRequired>
                    ),
                    children: [
                        {
                            index:
                                authCtx?.permissions?.wca_access === true &&
                                authCtx?.role !== 'system_admin',
                            element: (
                                <NamespaceControlled
                                    pathways={['wca_access', 'pip_wca_access']}
                                >
                                    <ReportsContainer pathway="wca" />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            index:
                                authCtx?.permissions?.pip_access === true &&
                                authCtx?.role !== 'system_admin',
                            element: (
                                <NamespaceControlled
                                    pathways={['pip_access', 'pip_wca_access']}
                                >
                                    <ReportsContainer pathway="pip" />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            index:
                                authCtx?.permissions?.pip_wca_access === true &&
                                authCtx?.role !== 'system_admin',
                            // path: 'management-information',
                            element: (
                                <NamespaceControlled
                                    pathways={[
                                        'wca_access',
                                        'pip_wca_access',
                                        'pip_access',
                                    ]}
                                >
                                    <MiContainer />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            path: 'management-information',
                            element: (
                                <NamespaceControlled
                                    pathways={[
                                        'wca_access',
                                        'pip_wca_access',
                                        'pip_access',
                                    ]}
                                >
                                    <MiContainer />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            path: 'reports',
                            children: [
                                {
                                    path: 'pip',
                                    element: (
                                        <NamespaceControlled
                                            pathways={[
                                                'pip_access',
                                                'pip_wca_access',
                                            ]}
                                        >
                                            <ReportsContainer pathway="pip" />
                                        </NamespaceControlled>
                                    ),
                                },
                                {
                                    path: 'wca',
                                    element: (
                                        <NamespaceControlled
                                            pathways={[
                                                'wca_access',
                                                'pip_wca_access',
                                            ]}
                                        >
                                            <ReportsContainer pathway="wca" />
                                        </NamespaceControlled>
                                    ),
                                },
                            ],
                        },
                        {
                            path: 'users',
                            element: (
                                <RoleControlled role={['super']}>
                                    <UserList />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'logs',
                            element: (
                                <RoleControlled
                                    role={['super', 'system_admin']}
                                >
                                    <AuditLogs />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'sms',
                            element: (
                                <NamespaceControlled
                                    pathways={['wca_access', 'pip_wca_access']}
                                >
                                    <MessagesList pathway="wca" />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            path: 'messages',
                            element: (
                                <NamespaceControlled
                                    pathways={['pip_access', 'pip_wca_access']}
                                >
                                    <MessagesList pathway="pip" />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            index: authCtx.role === 'system_admin',
                            element: (
                                <RoleControlled
                                    role={['system_admin', 'system_user']}
                                >
                                    <CustomerList />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'customers/:id',
                            element: (
                                <RoleControlled role={['system_admin']}>
                                    <CustomerSettings />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'system',
                            element: (
                                <RoleControlled role={['system_admin']}>
                                    <SystemUsersList />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'user-management',
                            element: (
                                <RoleControlled role={['system_admin']}>
                                    <InternalViewForUsers />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'node-config',
                            element: (
                                <RoleControlled role={['system_admin']}>
                                    <ONodeConfigurations />
                                </RoleControlled>
                            ),
                        },
                    ],
                },
            ])}
        />
    );
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
                <AuthProvider>
                    <ConfirmContextProvider>
                        <Main />
                        <ConfirmDialog />
                    </ConfirmContextProvider>
                </AuthProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
