import { useFormik } from 'formik';
import React from 'react';
import { object, string } from 'yup';
import { Box, Stack, TextField, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { createNewCustomer } from '../../services/customerServices';
import { specialChars } from './CustomerSettings';
import BasicSelect from '../../components/BasicSelect';

const setupConfig = object({
    name: string()
        .matches(specialChars, 'Please do not use special characters')
        .required('Customer name is required'),
    email: string()
        .email('Invalid email')
        .required('Email address is required'),
    pathway: string().required('Pathway is required'),
});

interface Props {
    onCompleted?: () => void;
}

const CustomerForm = (props: Props) => {
    const { onCompleted } = props;
    const [loading, setLoading] = React.useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            pathway: '',
        },
        validationSchema: setupConfig,
        initialErrors: {
            name: 'Name is required',
            email: 'Email address is required',
        },
        onSubmit: async (values) => {
            try {
                setLoading(true);
                // Try and add the customer
                await createNewCustomer({
                    ...values,
                    active: false,
                });
                enqueueSnackbar('Customer added successfully', {
                    variant: 'success',
                });
                if (onCompleted) {
                    onCompleted();
                }
            } catch (e) {
                console.error(e);
                const err = e as Error;
                enqueueSnackbar(err.message, { variant: 'error' });
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
                    <TextField
                        variant="outlined"
                        label="Customer Name"
                        fullWidth
                        onChange={formik.handleChange}
                        name="name"
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.name && formik.errors.name}
                        required
                    />
                    <TextField
                        variant="outlined"
                        label="Contact Email Address"
                        fullWidth
                        onChange={formik.handleChange}
                        name="email"
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.email && formik.errors.email}
                        required
                    />
                    <Stack direction="row" spacing={2}>
                        <BasicSelect
                            label="Pathway"
                            options={[
                                { value: 'pip', label: 'PIP' },
                                { value: 'wca', label: 'WCA' },
                                { value: 'pip_wca', label: 'PIP/WCA' },
                            ]}
                            callback={(val) => {
                                formik.setFieldValue('pathway', val);
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Box />
                    <Button
                        loading={loading}
                        variant="outlined"
                        type="submit"
                        disabled={loading || !formik.isValid}
                    >
                        Submit
                    </Button>
                </Stack>
            </form>
        </Box>
    );
};

export default CustomerForm;
