import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../config/FirebaseConfig';
import { Customer } from '../../services/customerServices';
import { useAuth } from '../../hooks/useAuth';

interface LotSelectorProps {
    onChangeHandler: (lots: string[]) => void;
    label?: string;
    customerId?: string;
    error?: boolean;
    onBlurHandler?: () => void;
    pathway: string;
    selectedRole: string;
}

const LotSelector = (props: LotSelectorProps) => {
    const {
        onChangeHandler,
        label,
        customerId,
        error,
        onBlurHandler,
        pathway,
        selectedRole,
    } = props;

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [options, setOptions] = useState<string[]>([]);

    const auth = useAuth();

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];
        const valToArray = Array.isArray(value) ? value : [value];
        setSelectedOptions(valToArray);
        if (onChangeHandler) {
            onChangeHandler(valToArray);
        }
    };

    const retrieveCustomerData = async (
        customerId: string,
    ): Promise<Customer | undefined> => {
        const ref = doc(db, 'customers', customerId);
        const docSnap = await getDoc(ref);
        if (!docSnap.exists()) {
            return undefined;
        }
        return docSnap.data() as Customer;
    };

    const getLotsForBothPathways = (data: Customer): string[] => {
        const pipLots = data['pip']?.operational_lot;
        const wcaLots = data['wca']?.operational_lot;
        const combinedOpts = [...pipLots, ...wcaLots].sort(
            (a: string, b: string) => a.localeCompare(b),
        );
        // reduce the array to unique values
        const uniqueOpts = combinedOpts.reduce(
            (acc: string[], curr: string) => {
                if (!acc.includes(curr)) {
                    acc.push(curr);
                }
                return acc;
            },
            [],
        );
        if (auth.role === 'system_admin') {
            return uniqueOpts;
        }
        // filter through and only return operational lots in the auth.operational_lot
        // that can be found in the customer operational lots
        return auth.operational_lot.filter((lot) => {
            return uniqueOpts.includes(lot);
        });
    };

    useEffect(() => {
        (async () => {
            if (!customerId) {
                return;
            }
            // reset the selected options
            setSelectedOptions([]);
            const customerData = await retrieveCustomerData(customerId);
            if (!customerData) {
                return;
            }
            if (auth.role === 'system_admin') {
                if (pathway === 'pip_wca') {
                    const lotOptions = getLotsForBothPathways(customerData);
                    setOptions(lotOptions);
                    return;
                }
                // system admin can see all lots
                setOptions(
                    customerData[pathway]?.operational_lot.sort(
                        (a: string, b: string) => a.localeCompare(b),
                    ) ?? [],
                );
                return;
            }
            if (pathway === 'pip_wca') {
                const lotOptions = getLotsForBothPathways(customerData);
                setOptions(lotOptions);
                return;
            }
            const ops = customerData[pathway]?.operational_lot;
            if (!ops) {
                return;
            }
            const filteredLts = auth.operational_lot.filter((lot) => {
                return ops.includes(lot);
            });
            setOptions(
                filteredLts.sort((a: string, b: string) => a.localeCompare(b)),
            );
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, pathway, auth.role, auth.operational_lot, selectedRole]);

    return (
        <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth>
                <InputLabel id="lots-simple-select-label">
                    {label ?? 'Lots'}
                </InputLabel>
                <Select
                    renderValue={(selected) => {
                        if (Array.isArray(selected)) {
                            return selected.join(', ');
                        }
                        return selected;
                    }}
                    label={label ?? 'Lots'}
                    id="lots-simple-select"
                    value={selectedOptions}
                    onChange={handleChange}
                    multiple={selectedRole !== 'admin'}
                    error={error}
                    onBlur={onBlurHandler}
                >
                    {options.map((lot, i) => (
                        <MenuItem key={`${lot}-${i}`} value={lot}>
                            <Checkbox
                                checked={selectedOptions.indexOf(lot) > -1}
                            />
                            <ListItemText primary={lot} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default LotSelector;
