import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';

export type InternalUser = {
    email: string;
    name: string;
    role: 'system_admin';
    id?: string;
};

/**
 * Creates a new system user
 * @param {object} user - The user to add
 * @returns {Promise<object>}
 */
export const addSystemUser = async (
    user: InternalUser,
): Promise<HttpsCallableResult<unknown>> => {
    return httpsCallable<InternalUser, unknown>(
        functions,
        'attestation-createsysuser',
    )(user);
};

/**
 * Removes a system user
 * @param {string} id - The id of the user to remove
 * @returns
 */
export const removeSystemUser = async (
    id: string,
): Promise<HttpsCallableResult<unknown>> => {
    return httpsCallable<{ id: string }, unknown>(
        functions,
        'attestation-deletesysuser',
    )({ id });
};
