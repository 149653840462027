import { Button } from '@mui/material';
import Papa from 'papaparse';
import React from 'react';
import { Customer } from '../../services/customerServices';
import { DownloadForOfflineRounded } from '@mui/icons-material';
import useConfirm from '../../hooks/useConfirm';

interface MultipleInviteTemplateProps {
    customer: Customer | null | undefined;
    pathway: string;
}

const MultipleInviteTemplate = (props: MultipleInviteTemplateProps) => {
    const { customer, pathway } = props;

    const { isConfirmed } = useConfirm();

    // check if the user has already downloaded the template
    // by looking for this value in localStorage
    const hasDownloadedTemplate = localStorage.getItem('hasDownloadedTemplate');

    const downloadTemplate = async () => {
        const template: {
            phone_number: string;
            language: string;
            [key: string]: any;
        } = {
            phone_number: '',
            language: 'en (English) OR cy (for Welsh)',
        };
        if (customer && customer[pathway].supplementary_info) {
            Object.keys(customer[pathway].supplementary_info).forEach((key) => {
                template[key] = 'FALSE';
            });
        }
        const csv = Papa.unparse([template]);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sms_template.csv';
        a.click();

        // set the value in localStorage to true
        localStorage.setItem('hasDownloadedTemplate', 'true');
    };

    React.useEffect(() => {
        (async () => {
            if (!hasDownloadedTemplate) {
                const confirm = await isConfirmed(
                    'It appears you have not downloaded the template yet. Would you like to download it now? \n You can always download it later by clicking the button.',
                );
                if (!confirm) {
                    return;
                }
                downloadTemplate();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Button
            onClick={downloadTemplate}
            startIcon={<DownloadForOfflineRounded />}
            variant="contained"
        >
            Template (Download)
        </Button>
    );
};

export default MultipleInviteTemplate;
