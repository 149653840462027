import { Timestamp } from '@firebase/firestore';

export type AdminAccessType = 'FB' | 'AD';

export type AdminRoleType = 'Super' | 'Admin' | 'System' | 'System Admin';

export type NamespaceType = 'PIP' | 'CHDA';

export enum Pathway {
    PIP = 'pip',
    WCA = 'wca',
    PIP_WCA = 'pip_wca',
}

export interface AdminUser {
    first_name: string;
    last_name: string;
    email: string;
    role: AdminRoleType;
    id?: string;
    customer_id: string;
    customer_name: string;
    name?: string;
    pathway: Pathway;
    permissions: {
        [key: string]: boolean;
    };
    status?: 'active' | 'inactive';
    operational_lot: string[];
}

export interface Assessment {
    first_name: string;
    last_name: string;
    date_of_birth: string;
    national_insurance_number: string;
    postcode: string;
    date_of_assessment: Timestamp;
    created_at: Timestamp;
    is_blocked: boolean;
    id: string;
    account_id: string;
    reviewed?: boolean;
    status: 'in-progress' | 'completed' | 'closed';
    assignee?: string;
}

/* TRANSCRIPT TYPES */

export interface StructuredTranscriptContent {
    header: string;
    subheader?: string;
    footer: string;
    body: string[];
}

export interface StructuredTranscript {
    header: string;
    subheader: string;
    content: StructuredTranscriptContent[];
}

export interface Transcript {
    questions?: {
        question_code: string;
        question_text: string[];
    }[];
    answers?: {
        question_code: string;
        text: string;
        answer: Array<{
            text: string;
            pdf_section: string;
            next_node: string;
            outcome_code: string;
            pdf_translation: string;
        }>;
        answer_text: string;
    }[];
    status: number;
    message?: string;
    transcript: StructuredTranscript[];
}

export interface TranscriptResponse extends Transcript {
    status: number;
    message?: string;
    flow_state?: string;
}

export interface InvitationMessage {
    phone_number: string;
    supplementary_info?: {
        [key: string]: boolean;
    };
    customer_id: string;
    customer_name: string;
    created_by: string;
    created_at: Timestamp;
    ttl: Date;
    delivery_date: Timestamp;
    pathway: Pathway;
    lot: string;
}

export type Customer = {
    active: boolean;
    pathway: Pathway;
    operational_lot: string[];
    name: string;
    email: string;
};
