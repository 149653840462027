import { Box, Container, Divider } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import React from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/FirebaseConfig';

const MiContainer = () => {
    const [miUrl, setMiUrl] = React.useState('');
    const auth = useAuth();

    React.useEffect(() => {
        let isMounted = true;
        (async () => {
            const ref = doc(db, 'customers', auth.customer_id);
            const docSnap = await getDoc(ref);
            if (isMounted) {
                setMiUrl(docSnap.data()?.mi_url);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [auth.customer_id]);

    return (
        <Container maxWidth="xl">
            <Divider>Management Information</Divider>
            <Box sx={{ width: '100%', height: '100%' }}>
                <iframe
                    title="Management Information"
                    src={
                        miUrl ??
                        'https://lookerstudio.google.com/embed/reporting/f70b9c52-b9b8-46b9-b023-26af4768b9d2/page/p_40h5uf08ed'
                    }
                    style={{ border: 'none', width: '80vw', height: '80vh' }}
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                ></iframe>
            </Box>
        </Container>
    );
};

export default MiContainer;
