import {
    Box,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import OlioNodeSelector from './OlioNodeSelector';
import BotSelector, { Flow } from './BotSelector';
import BasicMultiselect from '../../components/BasicMultiselect';

interface Props {
    formik: any;
    pathway: string;
    flowList: Array<Flow>;
    botId?: string;
}

const PathwayForm = (props: Props) => {
    const { formik, pathway, flowList, botId } = props;

    return (
        <Box>
            <Stack spacing={2}>
                <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    Content Templates
                </Typography>
                <TextField
                    variant="outlined"
                    label="Welcome Page (Prismic UID)"
                    fullWidth
                    id="welcome_page"
                    error={
                        formik.touched?.[pathway]?.welcome_page &&
                        Boolean(formik.errors?.[pathway]?.welcome_page)
                    }
                    helperText={
                        formik.touched?.[pathway]?.welcome_page &&
                        formik.errors?.[pathway]?.welcome_page
                    }
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            ),
                        },
                    }}
                    required
                    {...formik.getFieldProps(`${pathway}.welcome_page`)}
                />
                <TextField
                    variant="outlined"
                    label="Terms Summary (Prismic UID)"
                    fullWidth
                    id="terms_summary"
                    error={
                        formik.touched?.[pathway]?.terms_summary &&
                        Boolean(formik.errors?.[pathway]?.terms_summary)
                    }
                    helperText={
                        formik.touched?.[pathway]?.terms_summary &&
                        formik.errors?.[pathway]?.terms_summary
                    }
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            ),
                        },
                    }}
                    required
                    {...formik.getFieldProps(`${pathway}.terms_summary`)}
                />
                <TextField
                    variant="outlined"
                    label="Full Terms (Prismic UID)"
                    fullWidth
                    id="terms"
                    error={
                        formik.touched?.[pathway]?.terms &&
                        Boolean(formik.errors?.[pathway]?.terms)
                    }
                    helperText={
                        formik.touched?.[pathway]?.terms &&
                        formik.errors?.[pathway]?.terms
                    }
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            ),
                        },
                    }}
                    required
                    {...formik.getFieldProps(`${pathway}.terms`)}
                />
                <TextField
                    variant="outlined"
                    label="Privacy Policy (Prismic UID)"
                    fullWidth
                    id="privacy"
                    error={
                        formik.touched?.[pathway]?.privacy &&
                        Boolean(formik.errors?.[pathway]?.privacy)
                    }
                    helperText={
                        formik.touched?.[pathway]?.privacy &&
                        formik.errors?.[pathway]?.privacy
                    }
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            ),
                        },
                    }}
                    required
                    {...formik.getFieldProps(`${pathway}.privacy`)}
                />
                <TextField
                    variant="outlined"
                    label="FAQs (Prismic UID)"
                    fullWidth
                    id="faqs"
                    error={
                        formik.touched?.[pathway]?.faqs &&
                        Boolean(formik.errors?.[pathway]?.faqs)
                    }
                    helperText={
                        formik.touched?.[pathway]?.faqs &&
                        formik.errors?.[pathway]?.faqs
                    }
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            ),
                        },
                    }}
                    required
                    {...formik.getFieldProps(`${pathway}.faqs`)}
                />
                <TextField
                    variant="outlined"
                    label="Expired Page (Prismic UID)"
                    fullWidth
                    id="expired_page"
                    error={
                        formik.touched?.[pathway]?.expired_page &&
                        Boolean(formik.errors?.[pathway]?.expired_page)
                    }
                    helperText={
                        formik.touched?.[pathway]?.expired_page &&
                        formik.errors?.[pathway]?.expired_page
                    }
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            ),
                        },
                    }}
                    required
                    {...formik.getFieldProps(`${pathway}.expired_page`)}
                />
                <TextField
                    variant="outlined"
                    label="Outcome Screen (Prismic UID)"
                    fullWidth
                    id="outcome_screen"
                    error={
                        formik.touched?.[pathway]?.outcome_screen &&
                        Boolean(formik.errors?.[pathway]?.outcome_screen)
                    }
                    helperText={
                        formik.touched?.[pathway]?.outcome_screen &&
                        formik.errors?.[pathway]?.outcome_screen
                    }
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            ),
                        },
                    }}
                    required
                    {...formik.getFieldProps(`${pathway}.outcome_screen`)}
                />
                <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    SMS Comms
                </Typography>
                <TextField
                    variant="outlined"
                    label="Introductory SMS"
                    fullWidth
                    id="intro_sms"
                    multiline
                    minRows={4}
                    error={
                        formik.touched?.[pathway]?.intro_sms &&
                        Boolean(formik.errors?.[pathway]?.intro_sms)
                    }
                    helperText={
                        formik.touched?.[pathway]?.intro_sms &&
                        formik.errors?.[pathway]?.intro_sms
                    }
                    {...formik.getFieldProps(`${pathway}.intro_sms`)}
                />
                <TextField
                    variant="outlined"
                    label="Invite SMS"
                    fullWidth
                    id="invite_sms"
                    multiline
                    minRows={4}
                    error={
                        formik.touched?.[pathway]?.invite_sms &&
                        Boolean(formik.errors?.[pathway]?.invite_sms)
                    }
                    helperText={
                        formik.touched?.[pathway]?.invite_sms &&
                        formik.errors?.[pathway]?.invite_sms
                    }
                    {...formik.getFieldProps(`${pathway}.invite_sms`)}
                />
                <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    Chat Flow
                </Typography>
                <BotSelector
                    flowList={flowList}
                    onChange={(selectedFlow) => {
                        formik.setFieldValue(`${pathway}.question_set_config`, {
                            bot_id: selectedFlow.flowId,
                            version_label: selectedFlow.versionLabel,
                            bot_name: selectedFlow.flowName,
                        });
                    }}
                    defaultValue={botId}
                    required
                />
                <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    Olio Node Selector
                </Typography>
                <OlioNodeSelector
                    onChangehandler={(opts) => {
                        formik.setFieldValue(`${pathway}.supplementary_info`, {
                            ...opts,
                        });
                    }}
                    defaultValues={formik.values?.[pathway]?.supplementary_info}
                />
                <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    Operational Lot
                </Typography>
                <BasicMultiselect
                    options={['Lot 1', 'Lot 2', 'Lot 3', 'Lot 4', 'Lot 5']}
                    label="Lots"
                    onChangeCallback={(selectedValues) => {
                        formik.setFieldValue(
                            `${pathway}.operational_lot`,
                            selectedValues,
                        );
                    }}
                    defaultValue={formik.values?.[pathway]?.operational_lot}
                />
            </Stack>
        </Box>
    );
};

export default PathwayForm;
