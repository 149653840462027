import React from 'react';
import {
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { isPermitted, RoutePermission } from '../routes/NamespaceControlled';

interface Props {
    items: Array<{
        title: string;
        icon: React.ElementType;
        path: string;
        requiresNamespace?: string;
    }>;
}
const RoleNavigator = (props: Props) => {
    const { items } = props;

    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    return (
        <List component="nav">
            {items.map((item) => {
                if (
                    !isPermitted(
                        auth,
                        item.requiresNamespace as unknown as RoutePermission[],
                    )
                ) {
                    return null;
                }
                return (
                    <React.Fragment key={item.title}>
                        <ListItemButton
                            selected={location.pathname.endsWith(item.path)}
                            onClick={() => navigate(item.path)}
                        >
                            <ListItemIcon>
                                <item.icon />
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </React.Fragment>
                );
            })}
            <Divider />
        </List>
    );
};

export default RoleNavigator;
