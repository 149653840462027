import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import OtpVerification from './OtpVerification';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../../config/FirebaseConfig';

const SignInPasswordAndEmail = () => {
    const [state, setState] = React.useState<{
        email: string;
        password: string;
        policyAccepted: boolean;
        [propName: string]: any;
    }>({
        email: '',
        password: '',
        policyAccepted: false,
    });

    const [showverificationInput, setShowVerificationInput] =
        React.useState<boolean>(false);

    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const authObj = useAuth();

    const { enqueueSnackbar } = useSnackbar();

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setState({
            ...state,
            [`${name}`]: value,
        });
    };

    const disableButton = () => {
        const { email, password, policyAccepted } = state;
        return email.trim() === '' || password.trim() === '' || !policyAccepted;
    };

    const generateDeviceToken = () => {
        // get device token if exists
        const deviceToken = localStorage.getItem('deviceToken');
        if (deviceToken) {
            return deviceToken;
        }
        const newDeviceToken = (window.crypto as any).randomUUID();
        localStorage.setItem('deviceToken', newDeviceToken);
        return newDeviceToken;
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
    };

    const handleSubmit = async () => {
        try {
            if (!authObj || !authObj.handleLogin) {
                return;
            }
            setLoading(true);
            const deviceToken = generateDeviceToken();
            const res = await authObj.handleLogin(
                state.email,
                state.password,
                deviceToken,
            );
            if (!res?.data?.token) {
                setShowVerificationInput(true);
                return;
            }
            signInWithCustomToken(auth, res?.data.token);
        } catch (e) {
            console.error(e);
            enqueueSnackbar('Login attempt failed', {
                variant: 'error',
            });
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {showverificationInput ? (
                    <>
                        <OtpVerification
                            email={state.email}
                            onDismiss={() => {
                                setShowVerificationInput(false);
                                setLoading(false);
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleChangeInput}
                                error={state.email.trim() === ''}
                            />
                            <FormControl fullWidth>
                                <InputLabel htmlFor="password">
                                    Password
                                </InputLabel>
                                <OutlinedInput
                                    onChange={handleChangeInput}
                                    required
                                    inputProps={{
                                        name: 'password',
                                        id: 'password',
                                        autoComplete: 'current-password',
                                    }}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    error={state.password.trim() === ''}
                                />
                            </FormControl>
                            <FormHelperText component={'div'}>
                                We, EQL Limited (co no 11806513) provide the
                                Health Information Share (HIS) platform. HIS is
                                for the use of authorised users only. By
                                clicking on the OK button below and logging into
                                HIS, you agree to the following:
                                <ul>
                                    <li>
                                        You will abide by our acceptable use
                                        policy specified in paragraph 8 of our
                                        terms of use and only use HIS for its
                                        intended purpose, being the management
                                        of the process for universal credit
                                        applicants who complete their
                                        questionnaire using HIS.
                                    </li>
                                    <li>
                                        You are solely responsible for ensuring
                                        the device and connectivity used to
                                        access HIS are private and secure and
                                        for keeping any access credentials
                                        provided confidential.
                                    </li>
                                    <li>
                                        All individuals using HIS with or
                                        without authority, or in excess of their
                                        authority, are subject to having all of
                                        their activities in HIS and downloading
                                        or extracting from HIS monitored and
                                        recorded by EQL. Anyone using HIS
                                        expressly consents to such monitoring
                                        and is advised that if such monitoring
                                        reveals possible evidence of criminal
                                        activity, we may provide the evidence of
                                        such monitoring to law enforcement
                                        officials.
                                    </li>
                                    <li>
                                        To the maximum extent permitted by law,
                                        EQL excludes liability for all claims,
                                        actions and losses arising out of or in
                                        connection with your use of HIS.
                                    </li>
                                </ul>
                                EQL reserves the right to amend or revoke your
                                right to use HIS at any time.
                            </FormHelperText>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={state.policyAccepted}
                                        color="primary"
                                        inputProps={{
                                            'aria-label': 'Usage Policy',
                                        }}
                                        onChange={(evt) =>
                                            setState({
                                                ...state,
                                                policyAccepted:
                                                    evt.target.checked,
                                            })
                                        }
                                    />
                                }
                                label="By logging in, I accept the Acceptable Usage Policy above."
                                id="policy"
                            />
                            <Button
                                onClick={handleSubmit}
                                fullWidth
                                variant="contained"
                                disabled={disableButton()}
                                sx={{ mt: 3, mb: 2 }}
                                loading={loading}
                            >
                                Sign In
                            </Button>
                            <Grid
                                container
                                mb="20px"
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Grid>
                                    <Link
                                        href="/forgot-password"
                                        variant="body2"
                                    >
                                        Forgotten Password?
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default SignInPasswordAndEmail;
