import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { useAuth } from '../../hooks/useAuth';
import getSystemAdminNavigationItems from '../../roles/systemAdmin';
import RoleNavigator from '../../roles/RoleNavigator';
import getAdminNavigationItems, {
    getSuperAdminNavigationItems,
} from '../../roles/adminNavigationItems';

export const drawerWidth: number = 260;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const navigator: { [key: string]: any } = {
    system_admin: getSystemAdminNavigationItems(),
    admin: getAdminNavigationItems(),
    super: getSuperAdminNavigationItems(),
};

interface Props {
    open: boolean;
    onToggleDrawer: () => void;
}

const SideDrawer = (props: Props) => {
    const { open, onToggleDrawer } = props;
    const auth = useAuth();
    return (
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={onToggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            {auth && navigator[auth?.role] && (
                <RoleNavigator
                    items={navigator[auth?.role]}
                    //  onToggleDrawer={onToggleDrawer}
                />
            )}
        </Drawer>
    );
};

export default SideDrawer;
