import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import {
    AppBar,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    Stack,
    Tab,
    Tabs,
    TextField,
    Toolbar,
    styled,
} from '@mui/material';
import { useFormik } from 'formik';
import { object, string, mixed } from 'yup';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../hooks/useAuth';
import CallableServices from '../../services/CallableServices';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';
import CustomTabPanel from './CustomTabPanel';
import MultipleInvite from './MultipleInvite';
import { Customer } from '../../services/customerServices';
import parsePhoneNumberFromString from 'libphonenumber-js';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const NumberField = styled(TextField)(() => ({
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    '& input[type=number]': {
        MozAppearance: 'textfield',
    },
}));

interface Props {
    open: boolean;
    onClose: () => void;
    containerPathway: string;
}

interface SupplementaryInfo {
    [key: string]: boolean;
}

interface SupInfoDict {
    key: string;
    label: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validUKphone = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;

const registrationSchema = object({
    phone_number: string()
        .matches(
            validUKphone,
            'Please enter a valid UK mobile telephone number.',
        )
        .required('Phone number is required'),
    supplementary_info: mixed(),
    operational_lot: string().required('Operational lot is required'),
    language: string().required('Language is required'),
});

export default function CreateInvite(props: Props) {
    const { open, onClose, containerPathway } = props;

    const [loading, setLoading] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [customerInfo, setCustomerInfo] = React.useState<Customer | null>(
        null,
    );
    const [supplementaryInfoDict, setSupplementaryInfoDict] = React.useState<
        SupInfoDict[]
    >([]);

    const { enqueueSnackbar } = useSnackbar();

    const authObj = useAuth();

    const submitInvitation = async (data: any) => {
        await CallableServices.scheduleAssessmentInvitation(data);
        formik.resetForm();
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const getCustomerInfo = async () => {
        const ref = doc(db, 'customers', authObj.customer_id);
        const snapshot = await getDoc(ref);
        return snapshot.data() as Customer;
    };

    const handleCloseDialog = () => {
        // reset the form
        formik.resetForm();
        onClose();
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            phone_number: '',
            supplementary_info: {} as SupplementaryInfo,
            operational_lot: '',
            language: '',
        },
        validationSchema: registrationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: async (values) => {
            try {
                // check if the phone is a valid UK phone number
                const isValid = parsePhoneNumberFromString(
                    values.phone_number,
                    'GB',
                );
                if (!isValid?.number) {
                    formik.setErrors({
                        phone_number:
                            'Please enter a valid UK mobile telephone number.',
                    });
                }
                if (
                    requiresSupplementaryInfo() &&
                    Object.keys(formik.values.supplementary_info).length <= 0
                ) {
                    return;
                }
                setLoading(true);
                await submitInvitation({
                    ...values,
                    customer_id: authObj.customer_id,
                    customer_name: authObj.customer_name,
                    created_by: authObj.user?.email,
                    pathway: containerPathway,
                    supplementary_info: requiresSupplementaryInfo()
                        ? formik.values.supplementary_info
                        : null,
                });
                enqueueSnackbar('Invitation created successfully', {
                    variant: 'success',
                });
                handleCloseDialog();
            } catch (e) {
                console.error(e);
                const err = e as Error;
                enqueueSnackbar(err.message, { variant: 'error' });
            } finally {
                setLoading(false);
            }
        },
    });

    const oneOfSupInfoHasTobTrue = React.useCallback(() => {
        const hasTrue = Object.values(formik.values.supplementary_info).some(
            (value) => value === true,
        );
        return hasTrue as boolean;
    }, [formik.values.supplementary_info]);

    const requiresSupplementaryInfo = React.useCallback(() => {
        return (customerInfo &&
            Object.keys(customerInfo[containerPathway].supplementary_info)
                .length >= 1) as boolean;
    }, [customerInfo, containerPathway]);

    React.useEffect(() => {
        let isMounted = true;
        (async () => {
            const customerInfo = await getCustomerInfo();
            if (!isMounted) return;
            setCustomerInfo(customerInfo);
            // retrieve the supplementary info
            const ref = collection(db, 'supplementary_infos');
            const snapshots = await getDocs(ref);
            const dict = snapshots.docs.map((doc) => ({
                ...doc.data(),
            })) as SupInfoDict[];
            setSupplementaryInfoDict(dict);
            if (
                customerInfo[containerPathway].supplementary_info &&
                Object.keys(customerInfo[containerPathway].supplementary_info)
                    .length >= 1
            ) {
                formik.setFieldTouched('supplementary_info', true);
            }
        })();
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerPathway]);

    // Pre select the operational lot if there is only one
    React.useEffect(() => {
        if (authObj.operational_lot.length === 1) {
            formik.setFieldValue('operational_lot', authObj.operational_lot[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authObj.operational_lot]);

    const getSelectableSlots = React.useCallback(() => {
        // return only lots in the operational lot that can be found in the customers pathway lots
        return authObj.operational_lot.filter((lot) =>
            customerInfo?.[containerPathway]?.operational_lot?.includes(lot),
        );
    }, [customerInfo, authObj.operational_lot, containerPathway]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="refer-customer-dialog"
            fullScreen
            // maxWidth="md"
            // fullWidth
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseDialog}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="invitation tabs"
                >
                    <Tab label="Single Invite" {...a11yProps(0)} />
                    <Tab label="Multiple Invite" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Stack spacing={2} sx={{ mb: 4 }}>
                            <NumberField
                                type="numeric"
                                variant="outlined"
                                label="Mobile Phone"
                                fullWidth
                                id="phone_number"
                                error={
                                    formik.touched.phone_number &&
                                    Boolean(formik.errors.phone_number)
                                }
                                helperText={
                                    formik.touched.phone_number &&
                                    formik.errors.phone_number
                                }
                                {...formik.getFieldProps('phone_number')}
                                required
                                inputProps={{
                                    onWheel: (e: any) => e.target.blur(),
                                    onKeyDown: (e) =>
                                        ['e', 'E', '-'].includes(e.key) &&
                                        e.preventDefault(),
                                }}
                                value={formik.values.phone_number.toString()}
                            />
                        </Stack>
                        <Stack
                            spacing={2}
                            sx={{ mt: 4, mb: 4 }}
                            direction="row"
                            justifyContent="space-between"
                        >
                            {customerInfo &&
                                Object.keys(
                                    customerInfo[containerPathway]
                                        .supplementary_info,
                                ).length >= 1 && (
                                    <FormControl
                                        component="fieldset"
                                        required
                                        error={
                                            formik.touched.supplementary_info &&
                                            !oneOfSupInfoHasTobTrue()
                                        }
                                    >
                                        <FormLabel component="legend">
                                            Supplementary Information
                                        </FormLabel>
                                        <FormGroup>
                                            {Object.keys(
                                                customerInfo[containerPathway]
                                                    .supplementary_info,
                                            ).map((key) => {
                                                return (
                                                    <FormControlLabel
                                                        key={`supplementary_info_${key}`}
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    formik
                                                                        .values
                                                                        ?.supplementary_info[
                                                                        key
                                                                    ] ?? false
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        `supplementary_info.${key}`,
                                                                        e.target
                                                                            .checked,
                                                                    );
                                                                }}
                                                                name={`supplementary_info.${key}`}
                                                            />
                                                        }
                                                        label={
                                                            supplementaryInfoDict.find(
                                                                (info) =>
                                                                    info.key ===
                                                                    key,
                                                            )?.label ?? ''
                                                        }
                                                    />
                                                );
                                            })}
                                        </FormGroup>
                                    </FormControl>
                                )}
                            <FormControl
                                component="fieldset"
                                error={Boolean(formik.errors.operational_lot)}
                                required
                            >
                                <FormLabel component="legend">
                                    Select a lot
                                </FormLabel>
                                <FormGroup>
                                    {getSelectableSlots().map((lot: string) => {
                                        return (
                                            <FormControlLabel
                                                key={`operational_lot_${lot}`}
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            formik.values
                                                                .operational_lot ===
                                                            lot
                                                        }
                                                        onChange={() => {
                                                            formik.setFieldValue(
                                                                'operational_lot',
                                                                lot,
                                                            );
                                                        }}
                                                        name="operational_lot"
                                                        value={lot}
                                                    />
                                                }
                                                label={lot}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </FormControl>
                            <FormControl
                                component="fieldset"
                                error={Boolean(formik.errors.language)}
                                required
                            >
                                <FormLabel component="legend">
                                    Select Language
                                </FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        label="En (English)"
                                        control={
                                            <Checkbox
                                                checked={
                                                    formik.values.language ===
                                                    'en'
                                                }
                                                value={'en'}
                                                onChange={() => {
                                                    formik.setFieldValue(
                                                        'language',
                                                        'en',
                                                    );
                                                }}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label="Cy (Welsh/Cymraeg)"
                                        control={
                                            <Checkbox
                                                checked={
                                                    formik.values.language ===
                                                    'cy'
                                                }
                                                value={'cy'}
                                                onChange={() => {
                                                    formik.setFieldValue(
                                                        'language',
                                                        'cy',
                                                    );
                                                }}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </FormControl>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{ mr: 2 }}>
                            <Button
                                variant="outlined"
                                onClick={handleCloseDialog}
                                sx={{ mr: 2 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                loading={loading}
                                variant="contained"
                                disabled={
                                    !formik.isValid ||
                                    (requiresSupplementaryInfo() &&
                                        !oneOfSupInfoHasTobTrue())
                                }
                            >
                                Submit
                            </Button>
                        </Box>
                    </DialogActions>
                </form>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <MultipleInvite
                    customer={customerInfo}
                    onCloseCallback={handleCloseDialog}
                    invitePathway={containerPathway}
                />
            </CustomTabPanel>
        </Dialog>
    );
}
