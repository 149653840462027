import { signInWithCustomToken } from 'firebase/auth';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../../config/FirebaseConfig';
import { useAuth } from '../../hooks/useAuth';
import { Box, Button, Container, Stack } from '@mui/material';

const SigninWithCustomTokenContainer: React.FC = () => {
    const params = useParams<{ token: string }>();
    const [loggedIn, setLoggedIn] = React.useState(false);

    const authContext = useAuth();

    const navigate = useNavigate();

    React.useEffect(() => {
        // Sign in with custom token
        (async () => {
            if (!params.token) return;
            await signInWithCustomToken(auth, params.token);
            setLoggedIn(true);
            navigate('/dashboard');
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.token]);

    return (
        <Container maxWidth="md">
            <Box>
                <Stack>
                    <h1> You are impersonating... {authContext.user?.email}</h1>
                    {loggedIn && (
                        <Button
                            onClick={() => {
                                navigate('/dashboard');
                            }}
                            variant="outlined"
                        >
                            Start session
                        </Button>
                    )}
                </Stack>
            </Box>
        </Container>
    );
};

export default SigninWithCustomTokenContainer;
