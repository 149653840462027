import CustomerListIcon from '@mui/icons-material/BusinessOutlined';
import LogsIcon from '@mui/icons-material/HistoryEduOutlined';
import UsersIcon from '@mui/icons-material/PeopleAltOutlined';
import CustomerAdminIcon from '@mui/icons-material/PersonPinCircleRounded';
import NodesIcon from '@mui/icons-material/AccountTree';

const getSystemAdminNavigationItems = () => {
    return [
        {
            title: 'Customers',
            icon: CustomerListIcon,
            path: '/dashboard',
        },
        {
            title: 'System',
            path: 'system',
            icon: UsersIcon,
        },
        {
            title: 'Users',
            path: 'user-management',
            icon: CustomerAdminIcon,
        },
        {
            title: 'Audit Logs',
            icon: LogsIcon,
            path: 'logs',
        },
        {
            title: 'Node Config',
            icon: NodesIcon,
            path: 'node-config',
        },
    ];
};

export default getSystemAdminNavigationItems;
