import { Chip, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useAuth } from '../hooks/useAuth';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';

interface Props {
    assessmentId: string;
    assignee?: string | null;
    callback?: () => void;
}

const AssignmentComponent = (props: Props) => {
    const { assignee, assessmentId, callback } = props;
    const auth = useAuth();

    const handleAssign = async () => {
        await httpsCallable(
            functions,
            'assessments-assignit',
        )({
            assessment_id: assessmentId,
        });
        callback && callback();
    };

    const handleUnassign = async () => {
        await httpsCallable(
            functions,
            'assessments-unassign',
        )({
            assessment_id: assessmentId,
        });
        callback && callback();
    };

    return assignee === auth?.user?.email ? (
        <Chip
            onClick={() => {}}
            label={auth.user?.email}
            variant="outlined"
            onDelete={handleUnassign}
            deleteIcon={
                <Tooltip title="Unassign">
                    <DeleteIcon />
                </Tooltip>
            }
        />
    ) : (
        <Chip
            variant="outlined"
            label={assignee !== null ? `${assignee}` : 'Assign to me'}
            onClick={handleAssign}
            disabled={assignee !== null}
        />
    );
};

export default AssignmentComponent;
