import React, { useState } from 'react';
import {
    Checkbox,
    ListItemText,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    SelectChangeEvent,
    Box,
} from '@mui/material';

const BasicMultiselect = ({
    options,
    onChangeCallback,
    label,
    defaultValue,
}: {
    options: Array<string>;
    label?: string;
    preSelected?: string[];
    onChangeCallback: (val: string[]) => void;
    defaultValue: string[];
}) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>(
        defaultValue ?? [],
    );

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];
        setSelectedOptions(value);
        if (onChangeCallback) {
            onChangeCallback(value);
        }
        setSelectedOptions(event.target.value as string[]);
    };

    return (
        <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth>
                <InputLabel id="multiselect-label">
                    {label ?? 'Multiselect'}
                </InputLabel>
                <Select
                    labelId="multiselect-label"
                    multiple
                    value={selectedOptions}
                    onChange={handleChange}
                    renderValue={(selected) =>
                        (selected as string[]).join(', ')
                    }
                    label={label ?? 'Multiselect'}
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox
                                checked={selectedOptions.indexOf(option) > -1}
                            />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default BasicMultiselect;
