import {
    Container,
    Stack,
    Typography,
    Box,
    Avatar,
    CssBaseline,
    OutlinedInput,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
    FormHelperText,
    Button,
} from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { object, string } from 'yup';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../../config/FirebaseConfig';
import { useSnackbar } from 'notistack';

const Schema = object({
    password: string()
        .min(8, 'Too short')
        .required('Password is required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
});

export const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: Schema,
        onSubmit: async (values) => {
            try {
                const code = searchParams.get('oobCode');
                if (!code) {
                    enqueueSnackbar('There is no reset code', {
                        variant: 'error',
                    });
                    return;
                }

                setLoading(true);
                const accountEmail = await verifyPasswordResetCode(auth, code);
                if (accountEmail) {
                    await confirmPasswordReset(auth, code, values.password);
                    // go back to login page after password reset
                    navigate('/', { replace: true });
                    window.location.reload();
                }
            } catch (e) {
                const error = e as Error;
                console.error(e);
                enqueueSnackbar(error.message, { variant: 'error' });
                setLoading(false);
            } finally {
                setLoading(false);
            }
        },
    });
    return (
        <Container maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    sx={{
                        mt: 1,
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Reset your password
                    </Typography>
                    <Stack
                        spacing={2}
                        sx={{ mt: 4, mb: 4, width: '50%' }}
                        direction="column"
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                name="password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                            onMouseDown={(evt) =>
                                                evt.preventDefault()
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                }
                            />
                            {formik.touched.password && (
                                <FormHelperText>
                                    {formik.errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Button
                            type="submit"
                            variant="contained"
                            loading={loading}
                        >
                            Reset Password
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
};
