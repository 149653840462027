import React, { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Checkbox,
    ListItemText,
    SelectChangeEvent,
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';

interface Option {
    id: string;
    key: string;
    label: string;
}

interface OlioSelectorProps {
    onChangehandler: (preppedOptions: { [key: string]: boolean }) => void;
    defaultValues?: { [key: string]: boolean };
}

const OlioNodeSelector: React.FC<OlioSelectorProps> = (
    props: OlioSelectorProps,
) => {
    const { onChangehandler, defaultValues } = props;
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    useEffect(() => {
        // prevent the state update when the component is unmounted
        let isMounted = true;
        (async () => {
            const snapshot = await getDocs(
                collection(db, 'supplementary_infos'),
            );
            const opts = snapshot.docs.map((doc) => ({
                id: doc.id,
                key: doc.data().key,
                label: doc.data().label,
            })) as Option[];
            if (!isMounted) return;
            setOptions(opts);
        })();
        return () => {
            isMounted = false;
        };
    }, []);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const selOptions = event.target.value as string[];
        setSelectedOptions(selOptions);
        // prepare into an object of key-value pairs
        const prepped = selOptions.reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
        }, {} as { [key: string]: boolean });
        if (onChangehandler) {
            onChangehandler(prepped);
        }
    };

    React.useEffect(() => {
        let isMounted = true;
        if (!defaultValues) return;
        const defaultKeys = Object.keys(defaultValues);
        const selected = options
            .filter((option) => defaultKeys.includes(option.key))
            .map((option) => option.key);
        if (!isMounted) return;
        setSelectedOptions(selected);
        return () => {
            isMounted = false;
        };
    }, [defaultValues, options]);

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel id="olio-node-selector-label">
                Olio Node Selector
            </InputLabel>
            <Select
                labelId="olio-node-selector-label"
                multiple
                value={selectedOptions}
                onChange={handleChange}
                renderValue={(selected) =>
                    (selected as string[])
                        .map(
                            (value) =>
                                options.find((option) => option.key === value)
                                    ?.label,
                        )
                        .join(', ')
                }
                label="Olio Node Selector"
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.key}>
                        <Checkbox
                            checked={selectedOptions.indexOf(option.key) > -1}
                        />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default OlioNodeSelector;
