import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const RoleControlled = ({
    children,
    role,
}: {
    children: JSX.Element;
    role: string[];
}) => {
    const authCtx = useAuth();
    const location = useLocation();
    return role.includes(authCtx.role) ? (
        children
    ) : (
        <Navigate to="/dashboard" state={{ from: location }} replace />
    );
};

export default RoleControlled;
