import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';

interface GenericCallableResponse {
    status: number;
    message?: string;
}

interface PathwayProps {
    welcome_page: string;
    terms_summary: string;
    terms: string;
    privacy: string;
    faqs: string;
    expired_page: string;
    outcome_screen: string;
    intro_sms: string;
    invite_sms: string;
    question_set_config: {
        bot_id: string;
        version_label: string;
        bot_name: string;
    };
    supplementary_info: {
        [key: string]: boolean;
    };
    operational_lot: any;
}

export interface Customer {
    id?: string;
    name: string;
    email: string;
    active: boolean;
    logo_light: any;
    logo_dark: any;
    sms_config: {
        sender_label: string;
    };
    pathway: string;
    mi_url: string;
    pip: PathwayProps;
    wca: PathwayProps;
    supplementary_info?: {
        [key: string]: boolean;
    };
    [key: string]: any;
}

export interface CustomerPayload {
    id?: string;
    name: string;
    email: string;
    active: boolean;
    pathway: string;
}

export const createNewCustomer = async (
    data: CustomerPayload,
): Promise<HttpsCallableResult<GenericCallableResponse>> => {
    return httpsCallable<CustomerPayload, GenericCallableResponse>(
        functions,
        'customers-create',
    )(data);
};

export const removeCustomer = async (
    customerId: string,
): Promise<HttpsCallableResult<GenericCallableResponse>> => {
    return httpsCallable<{ customer_id: string }, GenericCallableResponse>(
        functions,
        'customers-expunge',
    )({ customer_id: customerId });
};
