import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

interface Props {
    label: string;
    callback?: (param: Date) => void;
    defaultDate?: Date;
    minDate?: Date;
}

export const BasicDatePicker = (props: Props) => {
    const { label, callback, defaultDate, minDate } = props;
    const [value, setValue] = React.useState<Date | null>(
        defaultDate ? defaultDate : new Date(),
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                value={value}
                onChange={(newValue: Date | null) => {
                    setValue(newValue);
                    if (callback && newValue) {
                        callback(newValue);
                    }
                }}
                minDate={minDate}
                slotProps={{
                    textField: {
                        helperText: 'DD/MM/YYYY',
                    },
                }}
                format="dd/MM/yyyy"
            />
        </LocalizationProvider>
    );
};

export const BasicDateTimePicker = (props: Props) => {
    const { label, callback, defaultDate } = props;
    const [value, setValue] = React.useState<Date | null>(
        defaultDate ? defaultDate : new Date(),
    );
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label={label}
                value={value}
                onChange={(newValue: Date | null) => {
                    setValue(newValue);
                    if (callback && newValue) {
                        callback(newValue);
                    }
                }}
            />
        </LocalizationProvider>
    );
};
