import React from 'react';
import { Box, Stack, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { endOfDay, startOfDay, subWeeks } from 'date-fns';
import {
    collection,
    getDocs,
    query,
    where,
    limit,
    orderBy,
    QueryConstraint,
} from 'firebase/firestore';
import { ThemeProvider } from '@mui/styles';
import { BasicDatePicker } from '../components/DatePicker';
import BasicSelect from '../components/BasicSelect';
import { db } from '../config/FirebaseConfig';
import { AdminUser } from '../types';
import { Log } from '../services/AuditLogService';
import { useAuth } from '../hooks/useAuth';

const AuditLogs = () => {
    const [userList, setUserList] = React.useState<
        Array<{ value: string; label: string }>
    >([{ value: 'all', label: 'All' }]);

    const [logs, setLogs] = React.useState<Array<Log>>([]);

    const [filter, setFilter] = React.useState<{
        from: Date;
        to: Date;
        user: string;
    }>({
        from: startOfDay(new Date()),
        to: endOfDay(new Date()),
        user: 'all',
    });

    const auth = useAuth();

    const handleFromDateCallback = (date: Date) => {
        setFilter({
            ...filter,
            from: startOfDay(date),
        });
    };

    const handleToDateCallback = (date: Date) => {
        setFilter({ ...filter, to: endOfDay(date) });
    };

    const handleUserChange = (user: string) => {
        setFilter({ ...filter, user });
    };

    React.useEffect(() => {
        (async () => {
            const ref = collection(db, 'audit_logs');
            const qConstraints: QueryConstraint[] = [
                where('customer_id', '==', auth.customer_id),
                where('created_at', '>=', filter.from),
                where('created_at', '<=', filter.to),
            ];
            if (filter.user !== 'all') {
                qConstraints.push(where('user', '==', filter.user));
            }
            qConstraints.push(orderBy('created_at', 'desc'), limit(500));
            const results = await getDocs(query(ref, ...qConstraints));
            const temp: Log[] = [];
            results.forEach((result) => {
                const data = { ...result.data() } as Log;
                temp.push(data);
            });
            setLogs(temp);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    React.useEffect(() => {
        (async () => {
            const ref = collection(db, 'admin_users');
            const _query = query(
                ref,
                where('customer_id', '==', auth.customer_id),
            );
            const results = await getDocs(_query);
            const temp: AdminUser[] = [];
            results.forEach((result) => {
                const data = { ...result.data(), id: result.id } as AdminUser;
                temp.push(data);
            });
            setUserList(
                [{ value: 'all', label: 'All' }].concat(
                    temp.map((user) => ({
                        value: user.email,
                        label:
                            user.name ?? `${user.first_name} ${user.last_name}`,
                    })),
                ),
            );
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Box mt={4} mb={4}>
                <Stack direction="row" spacing={2}>
                    <BasicDatePicker
                        label="Logged:From"
                        callback={handleFromDateCallback}
                        minDate={subWeeks(new Date(), 8)}
                    />
                    <BasicDatePicker
                        label="Logged:To"
                        callback={handleToDateCallback}
                        minDate={subWeeks(new Date(), 8)}
                    />
                    <BasicSelect
                        label="User"
                        options={userList}
                        callback={handleUserChange}
                        initialValue="all"
                    />
                </Stack>
            </Box>
            <ThemeProvider theme={createTheme()}>
                <MaterialTable
                    title="Logs"
                    data={logs}
                    columns={[
                        {
                            field: 'actor',
                            title: 'User',
                            headerStyle: {
                                paddingLeft: '24px',
                            },
                            cellStyle: {
                                paddingLeft: '24px',
                            },
                        },
                        {
                            field: 'created_at',
                            title: 'Timestamp',
                            render: React.useCallback((row) => {
                                return row.created_at
                                    .toDate()
                                    .toLocaleString('en-GB');
                            }, []),
                        },
                        {
                            field: 'description',
                            title: 'Description',
                        },
                        {
                            field: 'role',
                            title: 'User Role',
                        },
                        { field: 'action', title: 'Action' },
                    ]}
                    options={{
                        searchFieldVariant: 'outlined',
                        pageSize: 20,
                        pageSizeOptions: [20, 40, 60],
                    }}
                />
            </ThemeProvider>
        </Box>
    );
};

export default AuditLogs;
