import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { mixed, object, string, array } from 'yup';
import { useSnackbar } from 'notistack';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CircularProgress,
    Collapse,
    Divider,
    FormControlLabel,
    FormGroup,
    Paper,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { Flow } from './BotSelector';
import LogoUpload from '../../components/LogoUpload';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';
import { Customer } from '../../services/customerServices';
import uploadImages from '../../services/uploadImages';
import { listFlows } from '../../services/bot';
import BasicSelect from '../../components/BasicSelect';
import PathwayForm from './PathwayForm';

const MAX_FILE_SIZE = 204800;
export const specialChars = /^[aA-zZ0-9\s]+$/;

const conditionalSchema = (val: 'pip' | 'wca') =>
    string().when('pathway', {
        is: (pathway: string) => pathway === val,
        then: string().required('Field is required'),
        otherwise: string(),
    });

const setupSchema = object({
    name: string()
        .matches(specialChars, 'Please do not use special characters')
        .required('Name is required'),
    email: string()
        .email('Invalid email')
        .required('Email address is required'),
    sms_config: object({
        sender_label: string()
            .max(11, 'Sender name can be up to 11 characters long')
            .required('Sender label is required'),
    }),
    logo_light: mixed().when({
        is: (exists: any) => !!exists,
        then: (rule) =>
            rule
                .test(
                    'is-valid-size',
                    'This file is too big. Please upload a file smaller than 200KB',
                    (value) => value && value.size <= MAX_FILE_SIZE,
                )
                .required('Field is required'),
        otherwise: mixed(),
    }),
    logo_dark: mixed().when({
        is: (exists: any) => !!exists,
        then: (rule) =>
            rule
                .test(
                    'is-valid-size',
                    'This file is too big. Please upload a file smaller than 200KB',
                    (value) => value && value.size <= MAX_FILE_SIZE,
                )
                .required('Field is required'),
        otherwise: mixed(),
    }),
    mi_url: string().url('Invalid URL'),
    pathway: string().required('Field is required'),
    pip: object({
        welcome_page: conditionalSchema('pip'),
        terms_summary: conditionalSchema('pip'),
        terms: conditionalSchema('pip'),
        privacy: conditionalSchema('pip'),
        faqs: conditionalSchema('pip'),
        expired_page: conditionalSchema('pip'),
        outcome_screen: conditionalSchema('pip'),
        question_set_config: object({
            bot_id: conditionalSchema('pip'),
            version_label: conditionalSchema('pip'),
            bot_name: conditionalSchema('pip'),
        }),
        supplementary_info: mixed(),
        operational_lot: array().nullable(),
    }),
    wca: object({
        welcome_page: conditionalSchema('wca'),
        terms_summary: conditionalSchema('wca'),
        terms: conditionalSchema('wca'),
        privacy: conditionalSchema('wca'),
        faqs: conditionalSchema('wca'),
        expired_page: conditionalSchema('wca'),
        outcome_screen: conditionalSchema('wca'),
        question_set_config: object({
            bot_id: conditionalSchema('wca'),
            version_label: conditionalSchema('wca'),
            bot_name: conditionalSchema('wca'),
        }),
        supplementary_info: mixed(),
        operational_lot: array().nullable(),
    }),
});

const CustomerSettings = () => {
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [chatFlowLoading, setChatFlowLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [flowList, setFlowList] = useState<Array<Flow>>([]);

    const { name, email, active, logo_light, logo_dark, sms_config } =
        customer || {};

    const { sender_label } = sms_config || {};

    const params = useParams();
    const { id } = params;

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const getCustomerById = async (customerId: string) => {
        try {
            setLoading(true);

            const ref = doc(db, 'customers', customerId);
            const currentCustomer = await getDoc(ref);
            const currentCustomerData = {
                ...currentCustomer.data(),
                id: currentCustomer.id,
            } as Customer;
            setCustomer(currentCustomerData);
        } catch (e) {
            console.log(e);
            enqueueSnackbar(
                'There was some issues with loading customer data. Please try again later.',
                { variant: 'error' },
            );
        } finally {
            setLoading(false);
        }
    };

    const getChatFlowList = async () => {
        setChatFlowLoading(true);
        try {
            const botList = await listFlows();
            setFlowList(botList.data.chatFlowData);
        } catch (e) {
            console.error(e);
        } finally {
            setChatFlowLoading(false);
        }
    };

    const updateCustomer = async (values: Customer, logoUrls: any) => {
        const { logo_light, logo_dark, ...rest } = values;
        if (!id) {
            return;
        }

        const ref = doc(db, 'customers', id);
        const document = await getDoc(ref);
        if (document.exists()) {
            await updateDoc(ref, {
                ...rest,
                ...logoUrls,
                updated_at: serverTimestamp(),
            });
            return;
        }
    };

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name || '',
            email: email || '',
            active: active || false,
            logo_light: undefined,
            logo_dark: undefined,
            sms_config: {
                sender_label: sender_label || '',
            },
            pathway: customer?.pathway ?? '',
            mi_url: customer?.mi_url ?? '',
            pip: {
                welcome_page:
                    customer?.pip?.welcome_page ?? 'welcome-page-default',
                terms_summary:
                    customer?.pip?.terms_summary ??
                    'terms-summary-page-default',
                terms: customer?.pip?.terms ?? 'terms-page-default',
                privacy: customer?.pip?.privacy ?? 'privacy-page-default',
                faqs: customer?.pip?.faqs ?? 'faq-page-default',
                expired_page:
                    customer?.pip?.expired_page ?? 'expired-page-default',
                outcome_screen:
                    customer?.pip?.outcome_screen ?? 'outcome-screen-default',
                intro_sms: customer?.pip?.intro_sms ?? '',
                invite_sms: customer?.pip?.invite_sms ?? '',
                question_set_config: {
                    bot_id: customer?.pip?.question_set_config?.bot_id || '',
                    version_label:
                        customer?.pip?.question_set_config?.version_label || '',
                    bot_name:
                        customer?.pip?.question_set_config?.bot_name || '',
                },
                supplementary_info: customer?.pip?.supplementary_info ?? {},
                operational_lot: customer?.pip?.operational_lot ?? [],
            },
            wca: {
                welcome_page:
                    customer?.wca?.welcome_page ?? 'welcome-page-default',
                terms_summary:
                    customer?.wca?.terms_summary ??
                    'terms-summary-page-default',
                terms: customer?.wca?.terms ?? 'terms-page-default',
                privacy: customer?.wca?.privacy ?? 'privacy-page-default',
                faqs: customer?.wca?.faqs ?? 'faq-page-default',
                expired_page:
                    customer?.wca?.expired_page ?? 'expired-page-default',
                outcome_screen:
                    customer?.wca?.outcome_screen ?? 'outcome-screen-default',
                intro_sms: customer?.wca?.intro_sms ?? '',
                invite_sms: customer?.wca?.invite_sms ?? '',
                question_set_config: {
                    bot_id: customer?.wca?.question_set_config?.bot_id || '',
                    version_label:
                        customer?.wca?.question_set_config?.version_label || '',
                    bot_name:
                        customer?.wca?.question_set_config?.bot_name || '',
                },
                supplementary_info: customer?.wca?.supplementary_info ?? {},
                operational_lot: customer?.wca?.operational_lot ?? [],
            },
        },
        validationSchema: setupSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: async (values) => {
            try {
                if (!id) return;
                setSubmitting(true);
                let logoUrls = {};
                if (values.logo_light && values.logo_dark) {
                    logoUrls = await uploadImages(
                        [
                            {
                                file: values.logo_light,
                                fileName: 'logo_light',
                            },
                            {
                                file: values.logo_dark,
                                fileName: 'logo_dark',
                            },
                        ],
                        id,
                    );
                }
                if (values.logo_light && !values.logo_dark) {
                    logoUrls = await uploadImages(
                        [
                            {
                                file: values.logo_light,
                                fileName: 'logo_light',
                            },
                        ],
                        id,
                    );
                }
                if (!values.logo_light && values.logo_dark) {
                    logoUrls = await uploadImages(
                        [
                            {
                                file: values.logo_dark,
                                fileName: 'logo_dark',
                            },
                        ],
                        id,
                    );
                }

                let updatedValues = values;
                if (values.pathway === 'wca') {
                    updatedValues = {
                        ...values,
                        wca: {
                            ...values.wca,
                            operational_lot: values.wca.operational_lot,
                        },
                        pip: {} as any,
                    };
                }
                if (values.pathway === 'pip') {
                    updatedValues = {
                        ...values,
                        pip: {
                            ...values.pip,
                            operational_lot: values.pip.operational_lot,
                        },
                        wca: {} as any,
                    };
                }
                await updateCustomer(
                    {
                        ...updatedValues,
                        pip: {
                            ...values.pip,
                            operational_lot: values.pip.operational_lot,
                        },
                        wca: {
                            ...values.wca,
                            operational_lot: values.wca.operational_lot,
                        },
                    },
                    logoUrls,
                );
                enqueueSnackbar('Customer updated successfully', {
                    variant: 'success',
                });
                navigate('/dashboard');
            } catch (e) {
                console.error(e);
                const err = e as Error;
                setSubmitting(false);
                enqueueSnackbar(err.message, { variant: 'error' });
            }
        },
    });

    useEffect(() => {
        if (!id) {
            return;
        }
        getCustomerById(id);
        getChatFlowList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            {loading || chatFlowLoading ? (
                <Backdrop open sx={{ color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Typography
                        variant="h4"
                        component="h1"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            marginTop: '16px',
                        }}
                    >
                        Customer Settings
                    </Typography>
                    <Typography
                        variant="h6"
                        component="h2"
                        gutterBottom
                        sx={{
                            marginTop: '16px',
                            color: 'grey',
                        }}
                    >
                        Customer UID: {id}
                    </Typography>
                    <Paper sx={{ p: 2 }}>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                            >
                                Details
                            </Typography>
                            <Divider />
                            <Stack spacing={2} sx={{ mt: 2, mb: 4 }}>
                                <TextField
                                    variant="outlined"
                                    label="Customer Name"
                                    fullWidth
                                    id="name"
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    required
                                    {...formik.getFieldProps('name')}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Contact Email Address"
                                    fullWidth
                                    id="email"
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                    required
                                    {...formik.getFieldProps('email')}
                                />
                                <BasicSelect
                                    label="Pathways"
                                    options={[
                                        { value: 'pip', label: 'PIP' },
                                        { value: 'wca', label: 'WCA' },
                                        { value: 'pip_wca', label: 'PIP/WCA' },
                                    ]}
                                    defaultValue={formik.values.pathway}
                                    callback={(val) => {
                                        formik.setFieldValue('pathway', val);
                                    }}
                                    required
                                    error={
                                        formik.touched.pathway &&
                                        formik.errors.pathway
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    label="MI URL"
                                    fullWidth
                                    id="mi_url"
                                    error={
                                        formik.touched.mi_url &&
                                        Boolean(formik.errors.mi_url)
                                    }
                                    helperText={
                                        formik.touched.mi_url &&
                                        formik.errors.mi_url
                                    }
                                    {...formik.getFieldProps('mi_url')}
                                />
                            </Stack>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                Customer Status
                            </Typography>
                            <Divider />
                            <FormGroup
                                sx={{
                                    marginTop: '8px',
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            type="checkbox"
                                            name="active"
                                            onChange={formik.handleChange}
                                            checked={formik.values.active}
                                        />
                                    }
                                    label={
                                        formik.values.active
                                            ? 'Active'
                                            : 'Inactive'
                                    }
                                />
                            </FormGroup>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                Light Logo*
                            </Typography>
                            <Divider />
                            <Box pt={2}>
                                <LogoUpload
                                    formik={formik}
                                    fieldName="logo_light"
                                    previewBackground="#424c5f"
                                    logoSizes={[{ base: '34px', sm: '42px' }]}
                                    error={
                                        formik.values.logo_light &&
                                        formik.errors.logo_light
                                    }
                                    customerLogoUrl={logo_light}
                                    required={!logo_light}
                                />
                            </Box>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                Dark Logo*
                            </Typography>
                            <Divider />
                            <Box pt={2}>
                                <LogoUpload
                                    formik={formik}
                                    fieldName="logo_dark"
                                    error={
                                        formik.values.logo_dark &&
                                        formik.errors.logo_dark
                                    }
                                    customerLogoUrl={logo_dark}
                                    required={!logo_dark}
                                />
                            </Box>

                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                SMS
                            </Typography>
                            <Divider />
                            <Stack spacing={2} sx={{ mt: 2, mb: 4 }}>
                                <TextField
                                    variant="outlined"
                                    label="SMS Outbound From: Name"
                                    fullWidth
                                    id="sms_config.sender_label"
                                    error={
                                        formik.touched.sms_config
                                            ?.sender_label &&
                                        Boolean(
                                            formik.errors.sms_config
                                                ?.sender_label,
                                        )
                                    }
                                    helperText={
                                        formik.touched.sms_config
                                            ?.sender_label &&
                                        formik.errors.sms_config?.sender_label
                                    }
                                    required
                                    {...formik.getFieldProps(
                                        'sms_config.sender_label',
                                    )}
                                />
                            </Stack>

                            <Collapse in={formik.values.pathway !== 'wca'}>
                                <Typography
                                    variant="h6"
                                    component="h2"
                                    gutterBottom
                                    sx={{
                                        marginTop: '16px',
                                    }}
                                >
                                    PIP Pathway Configuration
                                </Typography>
                                <Card variant="outlined" sx={{ p: 2 }}>
                                    <Box>
                                        <PathwayForm
                                            formik={formik}
                                            pathway="pip"
                                            flowList={flowList}
                                            botId={
                                                customer?.pip
                                                    ?.question_set_config
                                                    ?.bot_id
                                            }
                                        />
                                    </Box>
                                </Card>
                            </Collapse>

                            <Collapse in={formik.values.pathway !== 'pip'}>
                                <Typography
                                    variant="h6"
                                    component="h2"
                                    gutterBottom
                                    sx={{
                                        marginTop: '16px',
                                    }}
                                >
                                    WCA Pathway Configuration
                                </Typography>
                                <Card variant="outlined" sx={{ p: 2 }}>
                                    <PathwayForm
                                        formik={formik}
                                        pathway="wca"
                                        flowList={flowList}
                                        botId={
                                            customer?.wca?.question_set_config
                                                ?.bot_id
                                        }
                                    />
                                </Card>
                            </Collapse>

                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                mt={2}
                            >
                                <Box />
                                <Box>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            navigate('/dashboard');
                                        }}
                                        sx={{ mr: 2 }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        loading={submitting}
                                        variant="contained"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Stack>
                        </form>
                    </Paper>
                </>
            )}
        </>
    );
};

export default CustomerSettings;
