import React, { useState } from 'react';
import {
    Container,
    TextField,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Button,
    Stack,
    Divider,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    DocumentData,
    onSnapshot,
    QuerySnapshot,
    updateDoc,
} from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';

type Item = {
    id?: string;
    key: string;
    label: string;
};

const ONodeConfigurations = () => {
    const [items, setItems] = useState<Item[]>([]);
    const [editIndex, setEditIndex] = useState<number | null | string>(null);
    const [newKey, setNewKey] = useState('');
    const [newLabel, setNewLabel] = useState('');

    const storeInFirestore = async (data: any) => {
        const ref = collection(db, 'supplementary_infos');
        await addDoc(ref, data);
    };

    const updateAnItemInFirestore = async (id: string, data: any) => {
        const ref = doc(db, 'supplementary_infos', id);
        await updateDoc(ref, data);
    };

    const removeAnItemInFirestore = async (id: string) => {
        const ref = doc(db, 'supplementary_infos', id);
        await deleteDoc(ref);
    };

    const handleAddItem = async () => {
        const payload = {
            key: newKey,
            label: newLabel,
        };
        await storeInFirestore(payload);
        setNewKey('');
        setNewLabel('');
    };

    const handleEditItem = (id: string | undefined) => {
        if (!id) return;
        setEditIndex(id);
        const item = items.find((item) => item.id === id);
        if (!item) return;
        setNewKey(item.key);
        setNewLabel(item.label);
    };

    const handleSaveEdit = () => {
        if (editIndex !== null) {
            // setItems(updatedItems);
            updateAnItemInFirestore(editIndex as string, {
                key: newKey,
                label: newLabel,
            });
            setEditIndex(null);
            setNewKey('');
            setNewLabel('');
        }
    };

    const handleRemoveItem = (index: string | undefined) => {
        if (!index) return;
        removeAnItemInFirestore(index);
        setEditIndex(null);
    };

    React.useEffect(() => {
        const handleSnapshot = async (
            snapshot: QuerySnapshot<DocumentData, DocumentData>,
        ) => {
            const data = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            })) as Item[];
            setItems(data);
        };
        const unsubscribe = onSnapshot(
            collection(db, 'supplementary_infos'),
            handleSnapshot,
        );
        return () => unsubscribe();
    }, []);

    return (
        <Container maxWidth="md">
            <h1>OLio Node Configurations</h1>
            <Stack spacing={2} direction={'row'} justifyContent="space-between">
                <TextField
                    label="Key"
                    value={newKey}
                    onChange={(e) => setNewKey(e.target.value)}
                    style={{ marginRight: 8 }}
                />
                <TextField
                    label="Label"
                    value={newLabel}
                    onChange={(e) => setNewLabel(e.target.value)}
                    style={{ marginRight: 8 }}
                />
                {editIndex !== null ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveEdit}
                        size="large"
                    >
                        Save
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={newKey === '' || newLabel === ''}
                        onClick={handleAddItem}
                    >
                        Add
                    </Button>
                )}
            </Stack>
            <List dense>
                {items.map((item, index) => (
                    <React.Fragment key={`${item.key}_${index}`}>
                        <ListItem>
                            <ListItemText
                                primary={item.label}
                                secondary={item.key}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    onClick={() => handleEditItem(item?.id)}
                                >
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    onClick={() => handleRemoveItem(item?.id)}
                                >
                                    <Delete />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        </Container>
    );
};

export default ONodeConfigurations;
