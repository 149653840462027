import {
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Box,
    Stack,
    Avatar,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
} from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState, useEffect } from 'react';
import { signInWithCustomToken } from 'firebase/auth';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../hooks/useAuth';
import { auth, functions } from '../../config/FirebaseConfig';
import { httpsCallable } from 'firebase/functions';

const CountdownComponent = ({
    threshold = 5,
    onResend,
    dismiss,
}: {
    threshold?: number;
    onResend?: () => Promise<void>;
    dismiss?: () => void;
}) => {
    const [countdown, setCountdown] = useState<number>(threshold);
    const [loading, setLoading] = useState<boolean>(false);

    const handleOnResend = () => {
        setCountdown(5);
        if (onResend) {
            setLoading(true);
            onResend().finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prev) => {
                if (prev <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prev - 1;
            });
        }, 60000);
        return () => clearInterval(interval);
    }, [threshold]);

    useEffect(() => {
        if (countdown === 0) {
            dismiss && dismiss();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdown]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="caption" component="h6">
                Enter your OTP code in <b>{countdown}</b> minute(s). Did not get
                the code?{' '}
                <Button loading={loading} onClick={handleOnResend}>
                    <Box
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '0.75rem',
                        }}
                        component="span"
                    >
                        resend
                    </Box>
                </Button>
            </Typography>
        </Box>
    );
};

const OtpVerification = ({
    email,
    onDismiss,
}: {
    email: string;
    onDismiss: () => void;
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [otpCode, setOtpCode] = useState<string>('');
    const [trustThisDevice, setTrustThisDevice] = useState<boolean>(false);

    const authy = useAuth();

    const { enqueueSnackbar } = useSnackbar();

    const generateDeviceToken = () => {
        // get device token if exists
        const deviceToken = localStorage.getItem('deviceToken');
        if (deviceToken) {
            return deviceToken;
        }
        const newDeviceToken = (window.crypto as any).randomUUID();
        localStorage.setItem('deviceToken', newDeviceToken);
        return newDeviceToken;
    };

    const resendOtp = async () => {
        try {
            // attempt resend OTP code
            await httpsCallable<{ email: string }, any>(
                functions,
                'attestation-resendotpcode',
            )({ email });
            enqueueSnackbar('OTP code has been resent', { variant: 'success' });
        } catch (e) {
            console.error(e);
            enqueueSnackbar('Failed to resend OTP', { variant: 'error' });
        }
    };

    const handleVerifyOtp = async () => {
        try {
            setLoading(true);
            // Verify OTP code
            if (!authy || !authy.verifyOtp) {
                return;
            }
            const deviceToken = generateDeviceToken();
            const res = await authy?.verifyOtp(
                otpCode,
                trustThisDevice,
                deviceToken,
            );
            if (!res?.data?.token) {
                enqueueSnackbar(`Unexpected response`, { variant: 'error' });
                return;
            }
            signInWithCustomToken(auth, res?.data.token);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(`Failed to verify OTP ${(e as Error)?.message}`, {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ mt: 2, width: '100%' }}>
            <Stack spacing={2} direction="column">
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Avatar
                        sx={{
                            m: 1,
                            bgcolor: 'secondary.main',
                        }}
                    >
                        <LockOutlinedIcon />
                    </Avatar>
                </Box>
                <Typography
                    component="h6"
                    variant="h6"
                    sx={{ mb: 2, textAlign: 'center' }}
                >
                    You should have received an email with a verification code.
                    Please enter it below.
                </Typography>
                <CountdownComponent
                    onResend={resendOtp}
                    dismiss={() => {
                        onDismiss();
                    }}
                />
                <FormControl fullWidth>
                    <InputLabel htmlFor="otpcode">OTP Code</InputLabel>
                    <OutlinedInput
                        id="otpcode"
                        endAdornment={
                            <InputAdornment position="end">
                                <CodeIcon />
                            </InputAdornment>
                        }
                        label="OTP Code"
                        onChange={(e) => setOtpCode(e.target.value)}
                    />
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={trustThisDevice}
                            color="primary"
                            inputProps={{
                                'aria-label': 'Trust this device',
                            }}
                            onChange={(evt) =>
                                setTrustThisDevice(evt.target.checked)
                            }
                        />
                    }
                    label="Trust this device"
                    id="trustdevice"
                />
                <Button
                    loading={loading}
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={handleVerifyOtp}
                >
                    Verify OTP
                </Button>
            </Stack>
        </Box>
    );
};

export default OtpVerification;
