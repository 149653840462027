import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import DOMPurify from 'dompurify';
import { array, bool, object, string } from 'yup';
import CallableServices from '../../services/CallableServices';
import { AdminUser, Pathway } from '../../types';
import { db } from '../../config/FirebaseConfig';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../hooks/useAuth';
import CustomerSelector from '../customers/CustomerSelector';
import LotSelector from '../customers/LotSelector';
import { doc, getDoc } from 'firebase/firestore';
import BasicSelect from '../../components/BasicSelect';

interface Props {
    open: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ROLES = [
    { value: 'super', label: 'Super' },
    { value: 'admin', label: 'Admin' },
];

const registrationSchema = object({
    first_name: string()
        .min(2, 'Too short')
        .max(50, 'Too long')
        .required('First name is required'),
    last_name: string()
        .min(2, 'Too short')
        .max(50, 'Too long')
        .required('Last name is required'),
    email: string()
        .email('Invalid email')
        .required('Email address is required'),
    role: string().required('Role is required'),
    customer_id: string().required('Customer is required'),
    customer_name: string().required('Customer name is required'),
    pathway: string().required('Pathway is required'),
    operational_lot: array(string()).required('Operational lot is required'),
    permissions: object({
        pip_access: bool(),
        wca_access: bool(),
        pip_wca_access: bool(),
    }),
});

export default function AddUserDialog(props: Props) {
    const { open, onClose } = props;
    const { enqueueSnackbar } = useSnackbar();

    const authObj = useAuth();

    const [loading, setLoading] = React.useState(false);

    const getPermissionListForRole = () => {
        if (formik.values.pathway === Pathway.PIP_WCA) {
            return [
                { value: 'pip_access', label: 'PIP' },
                { value: 'wca_access', label: 'WCA' },
                { value: 'pip_wca_access', label: 'PIP/WCA' },
            ];
        }
        if (formik.values.pathway === Pathway.PIP) {
            return [{ value: 'pip_access', label: 'PIP' }];
        }
        if (formik.values.pathway === Pathway.WCA) {
            return [{ value: 'wca_access', label: 'WCA' }];
        }
        return [];
    };

    const getPathwayForPermissionsSelected = (permissions: {
        [key: string]: boolean;
    }) => {
        if (permissions.pip_wca_access) {
            return Pathway.PIP_WCA;
        }
        if (permissions.pip_access) {
            return Pathway.PIP;
        }
        if (permissions.wca_access) {
            return Pathway.WCA;
        }
        return '';
    };

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            role: 'admin',
            customer_id: authObj?.customer_id ?? '',
            customer_name: authObj?.customer_name ?? '',
            pathway: null,
            operational_lot: [],
            permissions: {
                pip_access: false,
                wca_access: false,
                pip_wca_access: false,
            },
        },

        validationSchema: registrationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            try {
                if (authObj?.role === 'system_admin' && !values.customer_id) {
                    formik.setErrors({
                        customer_id: 'Customer is required',
                    });
                    return;
                }
                if (!values.operational_lot.length) {
                    formik.setErrors({
                        operational_lot: 'Operational lot is required',
                    });
                    enqueueSnackbar('Operational lot is required', {
                        variant: 'error',
                    });
                    return;
                }
                setLoading(true);
                const { first_name, last_name, email, role } = values;
                await CallableServices.addAdminUser({
                    ...values,
                    first_name: DOMPurify.sanitize(first_name),
                    last_name: DOMPurify.sanitize(last_name),
                    email: DOMPurify.sanitize(email),
                    role,
                    customer_id: values.customer_id,
                    customer_name: values.customer_name,
                    pathway: getPathwayForPermissionsSelected(
                        values.permissions,
                    ),
                } as AdminUser);
                enqueueSnackbar(
                    `User successfully created ${`an email has been sent to the user ${values.email}`}`,
                    { variant: 'success' },
                );
                setLoading(false);
                // reset state
                formik.resetForm();
                formik.setFieldValue('operational_lot', []);
                onClose();
            } catch (e) {
                const error = e as Error;
                console.error(e);
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        },
    });

    // handles situation where the admin user is a system user
    React.useEffect(() => {
        (async () => {
            if (!authObj || authObj.role !== 'system_admin') {
                return;
            }
            if (!formik.values.customer_id) {
                return;
            }
            // lets find the pathway of the customer
            // based on the customer id
            const ref = doc(db, 'customers', formik.values.customer_id);
            const snapshot = await getDoc(ref);
            if (snapshot.exists()) {
                const data = snapshot.data();
                formik.setFieldValue('pathway', data?.pathway);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authObj.role, formik.values.customer_id]);

    React.useEffect(() => {
        (async () => {
            const customerId = authObj.customer_id ?? formik.values.customer_id;
            if (!customerId) {
                return;
            }
            const authPathway = authObj.pathway;
            if (authPathway) {
                // assumes logged in user is a super
                formik.setFieldValue('pathway', authPathway);
                return;
            }
            // lets find the pathway of the customer
            // based on the customer id
            const ref = doc(db, 'customers', customerId);
            const snapshot = await getDoc(ref);
            if (snapshot.exists()) {
                const data = snapshot.data();
                formik.setFieldValue('pathway', data?.pathway);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authObj.customer_id, formik.values.customer_id]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Enter User Details</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
                        <TextField
                            variant="outlined"
                            label="First name"
                            fullWidth
                            id="first_name"
                            error={
                                formik.touched.first_name &&
                                Boolean(formik.errors.first_name)
                            }
                            helperText={
                                formik.touched.first_name &&
                                formik.errors.first_name
                            }
                            required
                            {...formik.getFieldProps('first_name')}
                        />
                        <TextField
                            variant="outlined"
                            label="Last name"
                            fullWidth
                            id="last_name"
                            error={
                                formik.touched.last_name &&
                                Boolean(formik.errors.last_name)
                            }
                            helperText={
                                formik.touched.last_name &&
                                formik.errors.last_name
                            }
                            required
                            {...formik.getFieldProps('last_name')}
                        />
                        <TextField
                            variant="outlined"
                            label="Email"
                            fullWidth
                            id="email"
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                            required
                            {...formik.getFieldProps('email')}
                        />
                    </Stack>
                    <Stack sx={{ mt: 2, mb: 4 }} direction="row" spacing={3}>
                        <Box sx={{ minWidth: 180 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Role Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formik.values.role}
                                    label="Role Type"
                                    onChange={formik.handleChange}
                                    sx={{ borderColor: 'red' }}
                                    name="role"
                                    error={
                                        formik.touched.role &&
                                        Boolean(formik.errors.role)
                                    }
                                >
                                    {ROLES.map((option) => (
                                        <MenuItem
                                            key={option.label}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        {authObj && authObj.role === 'system_admin' && (
                            <Box sx={{ minWidth: 220 }}>
                                <CustomerSelector
                                    onChange={(customer) => {
                                        formik.setFieldValue(
                                            'customer_id',
                                            customer.customerId,
                                        );
                                        formik.setFieldValue(
                                            'customer_name',
                                            customer.customerName,
                                        );
                                    }}
                                />
                            </Box>
                        )}
                        <BasicSelect
                            label="Permissions"
                            options={getPermissionListForRole()}
                            callback={(val) => {
                                formik.setFieldValue('permissions', {
                                    pip_access: false,
                                    wca_access: false,
                                    pip_wca_access: false,
                                    [val]: true,
                                });
                            }}
                        />
                        <LotSelector
                            customerId={
                                formik.values.customer_id ?? authObj.customer_id
                            }
                            onChangeHandler={(selectedLots) => {
                                formik.setFieldValue(
                                    'operational_lot',
                                    selectedLots,
                                );
                            }}
                            error={
                                formik.touched.operational_lot &&
                                formik.values.operational_lot.length === 0
                            }
                            onBlurHandler={() => {
                                formik.setFieldTouched('operational_lot', true);
                            }}
                            pathway={
                                getPathwayForPermissionsSelected(
                                    formik.values.permissions,
                                ) ?? authObj.pathway
                            }
                            selectedRole={formik.values.role}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ mr: 2 }}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                formik.resetForm();
                                onClose();
                            }}
                            sx={{ mr: 2 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            loading={loading}
                            variant="contained"
                            disabled={
                                Boolean(formik.errors.email) ||
                                Boolean(formik.errors.first_name) ||
                                Boolean(formik.errors.last_name)
                            }
                        >
                            Submit
                        </Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
}
