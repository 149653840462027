import React, { useState, useCallback } from 'react';
import {
    Box,
    Stack,
    Backdrop,
    CircularProgress,
    IconButton,
    createTheme,
    Checkbox,
} from '@mui/material';
import {
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    QueryConstraint,
    where,
} from 'firebase/firestore';
import MaterialTable from 'material-table';
import { endOfDay, format, startOfDay, subWeeks } from 'date-fns';
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@mui/styles';
import { db } from '../config/FirebaseConfig';
import { BasicDatePicker } from '../components/DatePicker';
import BasicSelect from '../components/BasicSelect';
import type { Assessment } from '../types';
import { PictureAsPdfSharp } from '@mui/icons-material';
import { useAuth } from '../hooks/useAuth';
import { reviewAssessment } from '../services/assessmentServices';
import { infoShareReportPdf, downloadPdfReport } from '../services/pdfService';
import AssignmentComponent from '../components/AssignmentComponent';
import csvDownloader from '../utils/csvDownloader';

const defaultMaterialTheme = createTheme();

interface ReportsContainerProps {
    pathway: string;
}

const ReportsContainer = (props: ReportsContainerProps) => {
    const { pathway } = props;
    const { enqueueSnackbar } = useSnackbar();

    const auth = useAuth();

    const [loading, setLoading] = useState(false);
    const [userList, setUserList] = useState<
        Array<{ label: string; value: string }>
    >([]);
    const [filter, setFilter] = useState<{
        from: Date;
        to: Date;
        status: string;
        reviewed: 'Reviewed' | 'Not Reviewed' | 'All' | string;
        lot: string;
        assignee: string;
    }>({
        from: startOfDay(new Date()),
        to: endOfDay(new Date()),
        status: 'completed',
        reviewed: 'Not Reviewed',
        lot: 'all',
        assignee: 'all',
    });

    const [assessmentList, setAssessmentList] = useState<Assessment[]>([]);

    const handleFromDateCallback = (date: Date) => {
        setFilter({
            ...filter,
            from: startOfDay(date),
        });
    };

    const operationalLots = [{ label: 'All', value: 'all' }].concat(
        auth?.operational_lot?.map((lot) => {
            return { value: lot, label: lot };
        }),
    );

    const getUsers = async () => {
        const ref = collection(db, 'admin_users');
        const q = query(ref, where('customer_id', '==', auth?.customer_id));
        const snapshots = await getDocs(q);
        let data: any[] = [];
        snapshots.forEach((snapshot) => {
            data.push({ ...snapshot.data(), id: snapshot.id });
        });
        // sort data by name in alphabetical order
        data.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        setUserList(
            data
                .filter((user) => user.email !== auth?.user?.email)
                .map((user) => ({
                    label: auth.user?.email === user.email ? 'Me' : user.name,
                    value: user.email,
                })),
        );
    };

    const handleToDateCallback = (date: Date) => {
        setFilter({ ...filter, to: endOfDay(date) });
    };

    const handleStatusChange = (status: string) => {
        setFilter({ ...filter, status });
    };

    const handleOnLotChange = (lot: string) => {
        setFilter({ ...filter, lot });
    };

    const handleAssigneeChange = (assignee: string) => {
        setFilter({ ...filter, assignee });
    };

    const downloadReport = async (
        pdfAsBase64String: string,
        rowData: Assessment,
    ) => {
        try {
            await downloadPdfReport(
                pdfAsBase64String,
                `${rowData.national_insurance_number}-Health_Information_Share_Report.pdf`,
            );
        } catch (error) {
            console.log(error);

            enqueueSnackbar(
                'There was an issue accessing this url, please try again.',
                {
                    variant: 'error',
                },
            );
        }
    };

    const onDownload = async (rowData: Assessment) => {
        setLoading(true);

        try {
            if (rowData.id) {
                const result = await infoShareReportPdf({
                    assessment_id: rowData.id,
                    pathway: pathway as 'pip' | 'wca',
                });
                const { pdfAsBase64String } = result.data;
                await downloadReport(pdfAsBase64String, rowData);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const getAssessments = async () => {
        let isActive = true;
        setLoading(true);
        try {
            const ref = collection(db, 'assessments');
            const constraints: QueryConstraint[] = [
                where('customer_id', '==', auth?.customer_id || ''),
                where('created_at', '>=', filter.from),
                where('created_at', '<=', filter.to),
                where('pathway', '==', pathway),
                where('archived', '==', false),
                orderBy('created_at', 'desc'),
                limit(300),
            ];
            if (filter.status !== 'all') {
                constraints.unshift(where('status', '==', filter.status));
            }
            if (filter.reviewed !== 'All') {
                constraints.unshift(
                    where('reviewed', '==', filter.reviewed === 'Reviewed'),
                );
            }
            if (filter.lot !== 'all') {
                constraints.unshift(where('operational_lot', '==', filter.lot));
            }
            if (filter.lot === 'all') {
                constraints.unshift(
                    where('operational_lot', 'in', auth.operational_lot),
                );
            }
            if (filter.assignee !== 'all') {
                constraints.unshift(where('assignee', '==', filter.assignee));
            }
            const q = query(ref, ...constraints);
            const snapshots = await getDocs(q);
            let data: any[] = [];
            snapshots.forEach((snapshot) => {
                data.push({ ...snapshot.data(), id: snapshot.id });
            });
            if (!isActive) {
                return;
            }
            setAssessmentList(data);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
        return () => {
            isActive = false;
        };
    };

    React.useEffect(() => {
        (async () => {
            if (!auth) {
                return;
            }
            await getAssessments();
            await getUsers();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, auth, pathway]);

    return (
        <>
            <Box sx={{ flex: 1, width: '100%' }}>
                <Box mt={4} mb={4}>
                    <Stack direction="row" spacing={2}>
                        <BasicDatePicker
                            label="Registered:From"
                            callback={handleFromDateCallback}
                            minDate={subWeeks(new Date(), 8)}
                        />
                        <BasicDatePicker
                            label="Registered:To"
                            callback={handleToDateCallback}
                            minDate={subWeeks(new Date(), 8)}
                        />
                        <BasicSelect
                            label="Status"
                            options={[
                                { value: 'completed', label: 'Completed' },
                                { value: 'in-progress', label: 'In-progress' },
                                { value: 'closed', label: 'Closed' },
                                { value: 'all', label: 'All' },
                            ]}
                            callback={handleStatusChange}
                            initialValue="completed"
                        />
                        <BasicSelect
                            label="Reviewed"
                            options={[
                                { value: 'Reviewed', label: 'Reviewed' },
                                {
                                    value: 'Not Reviewed',
                                    label: 'Not Reviewed',
                                },
                                { value: 'All', label: 'All' },
                            ]}
                            callback={(param) =>
                                setFilter({ ...filter, reviewed: param })
                            }
                            initialValue="Not Reviewed"
                        />
                        <BasicSelect
                            label="Lot"
                            options={operationalLots}
                            initialValue="all"
                            callback={handleOnLotChange}
                        />
                        <BasicSelect
                            label="Assignee"
                            options={[
                                { value: 'all', label: 'All' },
                                { value: auth.user?.email, label: 'Me' },
                            ].concat(userList)}
                            initialValue="all"
                            callback={handleAssigneeChange}
                        />
                    </Stack>
                </Box>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <MaterialTable
                        title={`${pathway?.toUpperCase()} Reports`}
                        data={assessmentList}
                        columns={[
                            {
                                field: 'reviewed',
                                title: 'Reviewed',
                                render: useCallback(
                                    (rowData) => (
                                        <Checkbox
                                            checked={rowData.reviewed || false}
                                            onChange={async (event) => {
                                                setLoading(true);
                                                await reviewAssessment(
                                                    rowData.id,
                                                    event.target.checked,
                                                    pathway as 'pip' | 'wca',
                                                );
                                                getAssessments();
                                                setLoading(false);
                                            }}
                                        />
                                    ),
                                    // eslint-disable-next-line react-hooks/exhaustive-deps
                                    [filter.reviewed],
                                ),
                                headerStyle: {
                                    paddingLeft: '24px',
                                },
                                cellStyle: {
                                    paddingLeft: '24px',
                                },
                            },
                            {
                                field: 'first_name',
                                title: 'Name',
                                render: (rowData) =>
                                    `${rowData.first_name} ${rowData.last_name}`,
                            },
                            {
                                field: 'operational_lot',
                                title: 'Lot',
                            },
                            {
                                field: 'date_of_birth',
                                title: 'Date of Birth',
                                render: useCallback((rowData) => {
                                    try {
                                        const dateStr = new Date(
                                            rowData.date_of_birth,
                                        ).toLocaleDateString();

                                        return dateStr;
                                    } catch (e) {
                                        return '';
                                    }
                                }, []),
                                customFilterAndSearch: (term, rowData) => {
                                    const dateOfBirth = rowData?.date_of_birth;

                                    const formattedDate = new Date(
                                        dateOfBirth,
                                    ).toLocaleDateString();

                                    return formattedDate.includes(term);
                                },
                            },
                            {
                                field: 'national_insurance_number',
                                title: 'NINO',
                            },
                            {
                                field: 'created_at',
                                title: 'Start',
                                render: useCallback((row) => {
                                    return row.created_at
                                        .toDate()
                                        .toLocaleDateString();
                                }, []),
                            },
                            {
                                field: 'completed_at',
                                title: 'End',
                                render: useCallback((row) => {
                                    const completedAt = row.completed_at;
                                    if (!completedAt) {
                                        return '-';
                                    }
                                    return completedAt
                                        .toDate()
                                        .toLocaleDateString();
                                }, []),
                            },
                            {
                                field: 'status',
                                title: 'Status',
                                render: useCallback((row) => {
                                    return (
                                        row.status[0].toUpperCase() +
                                        row.status.slice(1)
                                    );
                                }, []),
                            },
                            {
                                field: 'pdf_report',
                                title: 'Report',
                                render: useCallback((row: Assessment) => {
                                    if (row.status !== 'in-progress') {
                                        return (
                                            <IconButton
                                                onClick={() => onDownload(row)}
                                            >
                                                <PictureAsPdfSharp />
                                            </IconButton>
                                        );
                                    }
                                    return null;
                                    // eslint-disable-next-line
                                }, []),
                            },
                            {
                                field: 'assignee',
                                title: 'Assignee',
                                render: (rowData) => {
                                    return (
                                        <AssignmentComponent
                                            assessmentId={rowData.id}
                                            assignee={rowData?.assignee ?? null}
                                            callback={getAssessments}
                                        />
                                    );
                                },
                            },
                        ]}
                        options={{
                            searchFieldVariant: 'outlined',
                            pageSize: 20,
                            pageSizeOptions: [20, 40, 60],
                            exportCsv: (columns, renderData) => {
                                const jsonF = renderData.map((row) => {
                                    const {
                                        tableData,
                                        customer_id,
                                        customer_name,
                                        expiry_date,
                                        retention_date,
                                        updated_at,
                                        ...rest
                                    } = row;
                                    return {
                                        ...rest,
                                        created_at: format(
                                            row.created_at.toDate().getTime(),
                                            'yyyy-MM-dd',
                                        ),
                                        completed_at: row.completed_at
                                            ? format(
                                                  row.completed_at
                                                      ?.toDate()
                                                      .getTime(),
                                                  'yyyy-MM-dd',
                                              )
                                            : '',
                                    };
                                });
                                csvDownloader(
                                    jsonF,
                                    `${pathway}_reports_${new Date().toLocaleDateString()}`,
                                );
                            },
                            exportAllData: true,
                            exportButton: {
                                csv: true,
                                pdf: false,
                            },
                        }}
                    />
                </ThemeProvider>
            </Box>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default ReportsContainer;
