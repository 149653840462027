import { httpsCallable } from 'firebase/functions';
import Papa from 'papaparse';
import { functions } from '../config/FirebaseConfig';

const csvDownloader = async (data: any, fileName: string): Promise<void> => {
    try {
        // audit this first before proceeding
        await httpsCallable(
            functions,
            'attestation-auditlog',
        )({
            description: `Exported ${data.length} records to CSV`,
        });
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.csv`;
        a.click();
    } catch (e) {
        console.error(e);
    }
};

export default csvDownloader;
