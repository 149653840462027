import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormHelperText } from '@mui/material';

interface Option {
    value: any;
    label: string;
}

interface Props {
    label: string;
    options?: Array<Option>;
    callback?: (param: string) => void;
    initialValue?: string;
    required?: boolean;
    error?: string;
    defaultValue?: string;
    [key: string]: any;
}

export default function BasicSelect(props: Props) {
    const {
        label,
        options,
        callback,
        initialValue,
        required,
        error,
        defaultValue,
    } = props;
    const [value, setValue] = React.useState(defaultValue || '');

    const handleChange = (event: SelectChangeEvent) => {
        const statusValue = event.target.value as string;
        setValue(statusValue);
        if (callback) {
            callback(statusValue);
        }
    };

    React.useEffect(() => {
        if (!initialValue) {
            return;
        }
        setValue(initialValue);
    }, [initialValue]);

    return (
        <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth required={required} error={!!error}>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={label}
                    onChange={handleChange}
                    sx={{ borderColor: 'red' }}
                >
                    {options?.map((option, i) => (
                        <MenuItem
                            key={`${option.label}-${i}`}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </Box>
    );
}
