import React from 'react';
import {
    Box,
    Stack,
    Tooltip,
    Backdrop,
    CircularProgress,
    createTheme,
    Button,
} from '@mui/material';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@mui/styles';
import { Delete, PersonAdd } from '@mui/icons-material';
import AddUserDialog from './AddUserDialog';
import { AdminUser } from '../../types';
import CallableServices from '../../services/CallableServices';
import { useSnackbar } from 'notistack';
import useConfirm from '../../hooks/useConfirm';
import AdminUserRepo from '../../core/repositories/AdminUserRepo';
import { useAuth } from '../../hooks/useAuth';

export const UserList = () => {
    const [newUserDialogOpen, setNewUserDialogOpen] = React.useState(false);
    const [data, setData] = React.useState<AdminUser[]>([]);
    const [loading, setLoading] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const { isConfirmed } = useConfirm();

    const authCtx = useAuth();

    const getUsers = async () => {
        setLoading(true);
        const adminRepo = new AdminUserRepo();
        const allUsers = await adminRepo.getAdminUsersForCustomerId(
            authCtx.customer_id,
        );
        if (!allUsers) {
            setData([]);
            setLoading(false);
            return;
        }

        setData(allUsers);
        setLoading(false);
    };

    const deleteUser = async (rowData: AdminUser) => {
        if (!rowData || !rowData.id) {
            return;
        }
        const confirm = await isConfirmed(
            'Are you sure you want to delete this user?',
        );
        if (!confirm) {
            return;
        }
        if (authCtx.user?.uid === rowData.id) {
            enqueueSnackbar('You cannot delete yourself!', {
                variant: 'error',
            });
            return;
        }
        setLoading(true);
        await CallableServices.deleteAdminUser(rowData.id);
        enqueueSnackbar('User removed', { variant: 'success' });
        getUsers();
    };

    React.useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Box sx={{ mt: 4, mb: 4 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Box />
                    <Tooltip title="Add admin user">
                        <Button
                            startIcon={<PersonAdd />}
                            variant="outlined"
                            onClick={() => {
                                setNewUserDialogOpen(true);
                            }}
                        >
                            Add User
                        </Button>
                    </Tooltip>
                </Stack>
            </Box>
            <ThemeProvider theme={createTheme()}>
                <MaterialTable
                    title="Admin Users"
                    columns={[
                        { field: 'name', title: 'Name' },
                        { field: 'email', title: 'Email' },
                        { field: 'role', title: 'Role' },
                        {
                            field: 'operational_lot',
                            title: 'Lots',
                            render: (rowData) => {
                                if (rowData.operational_lot) {
                                    return rowData.operational_lot.join(', ');
                                }
                                return '';
                            },
                        },
                        {
                            field: 'permissions',
                            title: 'Permissions',
                            render: (rowData) => {
                                if (rowData.permissions) {
                                    return Object.keys(rowData.permissions)
                                        .map((key) => {
                                            if (
                                                rowData.permissions[key] ===
                                                true
                                            ) {
                                                return key;
                                            }
                                            return '';
                                        })
                                        .join(' \n');
                                }
                                return '';
                            },
                        },
                    ]}
                    data={data}
                    options={{
                        searchFieldVariant: 'outlined',
                        pageSize: 20,
                        pageSizeOptions: [20, 40, 60],
                    }}
                    actions={[
                        (rowData) => ({
                            icon: () => <Delete color="error" />,
                            tooltip: 'Remove',
                            onClick: (event, rowData) => {
                                deleteUser(rowData as AdminUser);
                            },
                            hidden: authCtx.user?.uid === rowData?.id,
                        }),
                    ]}
                />
            </ThemeProvider>
            <AddUserDialog
                open={newUserDialogOpen}
                onClose={() => {
                    setNewUserDialogOpen(false);
                    getUsers();
                }}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};
