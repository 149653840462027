import {
    DashboardCustomize,
    DataArrayOutlined,
    ListAltOutlined,
    PeopleAltRounded,
    SmsOutlined,
} from '@mui/icons-material';

export const getSuperAdminNavigationItems = () => {
    const navItems = [
        {
            title: 'Management Information',
            icon: DashboardCustomize,
            path: 'management-information',
            requiresNamespace: ['pip_access', 'pip_wca_access', 'wca_access'],
        },
        {
            title: 'Reports (PIP)',
            icon: ListAltOutlined,
            path: 'reports/pip',
            requiresNamespace: ['pip_access', 'pip_wca_access'],
        },
        {
            title: 'Reports (WCA)',
            icon: ListAltOutlined,
            path: 'reports/wca',
            requiresNamespace: ['wca_access', 'pip_wca_access'],
        },
        {
            title: 'Message Centre (WCA)',
            icon: SmsOutlined,
            path: 'sms',
            requiresNamespace: ['wca_access', 'pip_wca_access'],
        },
        {
            title: 'Message Centre (PIP)',
            icon: SmsOutlined,
            path: 'messages',
            requiresNamespace: ['pip_access', 'pip_wca_access'],
        },
        { title: 'Logs', icon: DataArrayOutlined, path: 'logs' },
        { title: 'Users', icon: PeopleAltRounded, path: 'users' },
    ];
    return navItems;
};

export default function getAdminNavigationItems() {
    const navItems = [
        {
            title: 'Reports (PIP)',
            icon: ListAltOutlined,
            path: 'reports/pip',
            requiresNamespace: ['pip_access', 'pip_wca_access'],
        },
        {
            title: 'Reports (WCA)',
            icon: ListAltOutlined,
            path: 'reports/wca',
            requiresNamespace: ['wca_access', 'pip_wca_access'],
        },
        {
            title: 'Message Centre (WCA)',
            icon: SmsOutlined,
            path: 'sms',
            requiresNamespace: ['wca_access', 'pip_wca_access'],
        },
        {
            title: 'Message Centre (PIP)',
            icon: SmsOutlined,
            path: 'messages',
            requiresNamespace: ['pip_access', 'pip_wca_access'],
        },
        {
            title: 'Management Information',
            icon: DashboardCustomize,
            path: 'management-information',
            requiresNamespace: ['pip_access', 'pip_wca_access'],
        },
    ];
    return navItems;
}
