import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { AppAuth } from '../authentication/AuthenticationContext';

export type RoutePermission = 'wca_access' | 'pip_wca_access' | 'pip_access';

export const isPermitted = (
    auth: AppAuth,
    permissions: RoutePermission[],
): boolean => {
    if (!permissions) {
        if (auth.role === 'super' || auth.role === 'system_admin') {
            return true;
        }
        return false;
    }
    return permissions.some((pathway) => {
        if (!auth.permissions) return false;
        return auth.permissions[pathway] === true;
    });
};

const NamespaceControlled = ({
    children,
    pathways,
}: {
    children: JSX.Element;
    pathways: RoutePermission[];
}) => {
    const authCtx = useAuth();
    const location = useLocation();

    return isPermitted(authCtx, pathways) ? (
        children
    ) : (
        <Navigate to="/dashboard" state={{ from: location }} replace />
    );
};

export default NamespaceControlled;
