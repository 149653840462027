import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';
import {
    AdminUser,
    InvitationMessage,
    Transcript,
    TranscriptResponse,
} from '../types';

class CallableServices {
    static async syncSpreadsheet(
        alsoUpdateMedicalList?: boolean,
    ): Promise<HttpsCallableResult<{ status: number; message?: string }>> {
        return httpsCallable<any, { status: number; message?: string }>(
            functions,
            'dataSync-syncNewSheet',
        )({ shouldUpdate: alsoUpdateMedicalList });
    }

    static async addAdminUser(
        newUser: AdminUser,
    ): Promise<
        HttpsCallableResult<{ status: number; message?: string; link?: string }>
    > {
        return httpsCallable<
            AdminUser,
            { status: number; message?: string; link?: string }
        >(
            functions,
            'attestation-createuser',
        )({
            ...newUser,
            name: `${newUser.first_name} ${newUser.last_name}`,
            status: 'active',
        });
    }

    static async deleteAdminUser(
        userId: string,
    ): Promise<HttpsCallableResult<{ status: number; message?: string }>> {
        return httpsCallable<
            { uid: string },
            { status: number; message?: string }
        >(
            functions,
            'attestation-deleteuser',
        )({ uid: userId });
    }

    static async getAccessToken(userId: string): Promise<
        HttpsCallableResult<{
            status: number;
            message?: string;
            token?: string;
        }>
    > {
        return httpsCallable<
            { user_id: string },
            { status: number; message?: string }
        >(
            functions,
            'auth-authenticate',
        )({ user_id: userId });
    }

    static async retrieveAssessmentReport(
        id: string,
    ): Promise<HttpsCallableResult<TranscriptResponse>> {
        return httpsCallable<{ assessment_id: string }, Transcript>(
            functions,
            'chat-handleRetrieval',
        )({ assessment_id: id });
    }

    static async sendSimpleMessage(payload: {
        to: string;
        message: string;
        from?: string;
    }): Promise<HttpsCallableResult> {
        return httpsCallable(functions, 'sms-sendSMS')({ ...payload });
    }

    /**
     * Queue up a PIP invite message
     * @param {object} payload
     * @returns
     */
    static async scheduleAssessmentInvitation(
        payload: InvitationMessage,
    ): Promise<HttpsCallableResult> {
        return httpsCallable(functions, 'sms-queueup')(payload);
    }

    static async cancelPIPInvite(
        inviteId: string,
    ): Promise<HttpsCallableResult> {
        return httpsCallable(functions, 'sms-rescind')({ id: inviteId });
    }

    static async signin(payload: {
        email: string;
        password: string;
        device_token?: string;
    }) {
        return httpsCallable(functions, 'attestation-login')(payload);
    }

    static async signout() {
        return httpsCallable(functions, 'attestation-signout')({});
    }

    static async verifyOtp(
        otpCode: string,
        trustThisDevice?: boolean,
        deviceToken?: string,
    ): Promise<HttpsCallableResult> {
        return httpsCallable(
            functions,
            'attestation-verifyotp',
        )({
            otp: otpCode,
            trust_device: trustThisDevice,
            device_token: deviceToken,
        });
    }
}

export default CallableServices;
