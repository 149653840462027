import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Stack,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import React, { useCallback } from 'react';
import parsePhoneNumberFromString, {
    isValidPhoneNumber,
} from 'libphonenumber-js';
import { useAuth } from '../../hooks/useAuth';
import { Customer } from '../../services/customerServices';
import CallableServices from '../../services/CallableServices';
import { InvitationMessage } from '../../types';
import { useSnackbar } from 'notistack';
import { serverTimestamp } from 'firebase/firestore';
import MultipleInviteTemplate from './MultipleInviteTemplate';

interface RawContact {
    [key: string]: string;
}

type Recipient = {
    phone_number?: string;
    language?: string;
    [key: string]: any;
};

interface Props {
    customer?: Customer | null;
    onCloseCallback?: () => void;
    invitePathway: string;
}

const MultipleInvite = (props: Props) => {
    const { customer, onCloseCallback, invitePathway } = props;
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [fileErrors, setFileErrors] = React.useState<string[]>([]);
    const [acceptedFile, setAcceptedFile] = React.useState<File | null>(null);
    const [contacts, setContacts] = React.useState<Array<Recipient>>([]);
    const [uploadLot, setUploadLot] = React.useState<string | null>(null);

    const auth = useAuth();

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        // reset state
        setAcceptedFile(null);
        setContacts([]);
        setFileErrors([]);
        setUploadLot(null);
        onCloseCallback?.();
    };

    const onDrop = useCallback((acceptedFiles) => {
        setAcceptedFile(acceptedFiles[0]);
        // clear errors
        setFileErrors([]);
        // Do something with the files
        Papa.parse(acceptedFiles[0], {
            header: true,
            complete: (results) => {
                const data = results.data as Array<RawContact>;
                const { fields } = results.meta;
                if (!fields || !fields.length) {
                    return;
                }
                if (results.errors.length) {
                    setFileErrors(results.errors.map((error) => error.message));
                    return;
                }
                // retrieve the data from the csv file using the fields in the metadata for each row
                const values = data.map((row) => {
                    const rowData: { [key: string]: any } = {};
                    fields.forEach((field) => {
                        rowData[field] =
                            field === 'phone_number'
                                ? parsePhoneNumberFromString(row[field], 'GB')
                                      ?.number
                                : row[field] === 'TRUE'
                                ? true
                                : row[field];
                        if (row[field] === 'FALSE') {
                            rowData[field] = false;
                        }
                    });
                    return rowData;
                });
                // filter the values and exclude fields that have a falsy value
                const excludedFalsys = values.map((value) => {
                    Object.keys(value).forEach((key) => {
                        if (
                            typeof value[key] === 'boolean' &&
                            value[key] === false
                        ) {
                            delete value[key];
                        }
                    });
                    return value;
                });
                setContacts(excludedFalsys);
            },
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
        },
    });

    const validateContacts = (data: Recipient[]) => {
        const errors: string[] = [];
        data.forEach((contact, rowIndex) => {
            Object.keys(contact).forEach((key) => {
                if (key === 'phone_number') {
                    const isValidContactNumber = isValidPhoneNumber(
                        contact.phone_number ?? '',
                        'GB',
                    );
                    if (!isValidContactNumber) {
                        errors.push(
                            `Invalid phone number: ${
                                contact.phone_number
                            } on Row ${rowIndex + 1}`,
                        );
                    }
                    return;
                }
                if (key === 'language') {
                    if (
                        contact.language === undefined ||
                        contact.language.trim() === ''
                    ) {
                        errors.push(
                            `Invalid language: ${contact.language} on Row ${
                                rowIndex + 1
                            }`,
                        );
                        return;
                    }
                    if (
                        contact.language !== 'en' &&
                        contact.language !== 'cy'
                    ) {
                        errors.push(
                            `Invalid language: ${contact.language} on Row ${
                                rowIndex + 1
                            }, use 'en' or 'cy'`,
                        );
                    }
                }
                // check if the keys in supplementary_info appear in the contact object
                if (
                    customer &&
                    customer[invitePathway].supplementary_info &&
                    !Object.keys(
                        customer[invitePathway].supplementary_info,
                    ).includes(key) &&
                    key !== 'phone_number' &&
                    key !== 'language'
                ) {
                    errors.push(
                        `${key} on Row ${
                            rowIndex + 1
                        } does not match, expected one of [${Object.keys(
                            customer[invitePathway].supplementary_info,
                        ).join(', ')}]`,
                    );
                }
            });
        });
        setFileErrors(errors);
        return errors.length === 0;
    };

    const submitContacts = async () => {
        if (!uploadLot) {
            enqueueSnackbar('Please select a lot', { variant: 'error' });
            return;
        }
        try {
            setIsSubmitting(true);
            const promises: any[] = [];
            contacts.forEach((contact) => {
                // supplementary_info
                const additionalInfo: { [key: string]: boolean } = {};
                if (customer && customer[invitePathway].supplementary_info) {
                    Object.keys(
                        customer[invitePathway].supplementary_info,
                    ).forEach((key) => {
                        if (contact[key]) {
                            additionalInfo[key] = contact[key] as boolean;
                        }
                    });
                }
                const payload = {
                    ...contact,
                    customer_id: auth.customer_id,
                    customer_name: auth.customer_name,
                    created_by: auth.user?.email ?? '',
                    created_at: serverTimestamp(),
                    pathway: invitePathway,
                    operational_lot: uploadLot,
                    supplementary_info: {
                        ...additionalInfo,
                    },
                };
                promises.push(
                    CallableServices.scheduleAssessmentInvitation(
                        payload as unknown as InvitationMessage,
                    ),
                );
            });

            await Promise.all(promises);
            enqueueSnackbar('Invitations sent successfully', {
                variant: 'success',
            });
            handleClose();
        } catch (e) {
            console.error(e);
        } finally {
            setIsSubmitting(false);
        }
    };

    React.useEffect(() => {
        if (!contacts.length) {
            return;
        }
        validateContacts(contacts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts]);

    // pre select the lot if there is only one available
    React.useEffect(() => {
        if (auth?.operational_lot?.length === 1) {
            setUploadLot(auth.operational_lot[0]);
        }
    }, [auth]);

    const getSelectableSlots = React.useCallback(() => {
        // return only lots in the operational lot that can be found in the customers pathway lots
        return auth.operational_lot.filter((lot) =>
            customer?.[invitePathway]?.operational_lot?.includes(lot),
        );
    }, [customer, auth.operational_lot, invitePathway]);

    return (
        <>
            <DialogContent>
                <Stack spacing={2} direction="row">
                    <MultipleInviteTemplate
                        customer={customer}
                        pathway={invitePathway}
                    />
                    <Box />
                </Stack>
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="space-evenly"
                >
                    <Box p={2}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div>
                                <img
                                    alt="Select file"
                                    style={{ width: 130 }}
                                    src="/static/undraw_add_file2_gvbb.svg"
                                />
                            </div>
                            {isDragActive ? (
                                <p>Drop the files here ...</p>
                            ) : contacts.length ? null : (
                                <p>
                                    Drag 'n' drop your csv files here, or click
                                    to select files
                                </p>
                            )}
                        </div>
                    </Box>
                    {acceptedFile !== null && (
                        <Box p={2}>
                            Total number of rows uploaded {contacts.length}
                        </Box>
                    )}
                    {fileErrors.length > 0 && (
                        <Box p={2}>
                            <h4>Errors</h4>
                            <p>Please fix the following errors and try again</p>
                            <p>
                                If you are using the template, please ensure you
                                have an up to date version
                            </p>
                            <ul>
                                {fileErrors.map((error, index) => (
                                    <li key={`error_${index}`}>{error}</li>
                                ))}
                            </ul>
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Select a lot
                            </FormLabel>
                        </FormControl>
                        <FormGroup>
                            {getSelectableSlots()?.map((lot: string) => {
                                return (
                                    <FormControlLabel
                                        key={`operational_lot_${lot}`}
                                        control={
                                            <Checkbox
                                                checked={uploadLot === lot}
                                                onChange={() => {
                                                    setUploadLot(lot);
                                                }}
                                                name="operational_lot"
                                                value={lot}
                                            />
                                        }
                                        label={lot}
                                    />
                                );
                            })}
                        </FormGroup>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Box sx={{ mr: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        sx={{ mr: 2 }}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            isSubmitting ||
                            contacts.length === 0 ||
                            !uploadLot ||
                            fileErrors.length > 0
                        }
                        variant="contained"
                        onClick={submitContacts}
                        loading={isSubmitting}
                    >
                        {contacts.length
                            ? `Invite ${contacts.length} recipients`
                            : 'No recipients to invite'}
                    </Button>
                </Box>
            </DialogActions>
        </>
    );
};

export default MultipleInvite;
