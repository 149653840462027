import { createTheme } from '@mui/material';

// refer to page 6 in dev/docs/976220434MaximusUK-BrandGuidelines_2021.pdf
export const maximusThemeColors = {
    primary: {
        darkest: '#2b254b',
        dark: '#545487',
        light: '#7475b7',
        lightest: '#dbdcf4',
    },
    accent: {
        green: '#8abd55',
        blue: '#7098cf',
        red: '#e07e34',
        pink: '#ca7caf',
        yellow: '##d8b423',
    },
    other: {
        green: '#4f7b39',
        blueA: '#7098cf',
        red: '#c95828',
        whiteA: '#d9eccd',
        blueB: '#e5eaf6',
        whiteB: '#fce8d6',
        purple: '#5f295f',
        yellowA: '#edda73',
        greyA: '#363636',
        pink: '#fce8fe',
        yellowB: '#f2edc2',
        greyB: '#dcdcdc',
    },
    teal: {
        dark: '#036e62',
        mid: '#66bbb0',
        light: '#cee4e1',
    },
};

export const commonThemeColors = {
    background: '#f3f2f1',
};

export default createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#263238',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: "#0066ff",
            main: '#01579B',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffcc00',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: '"Arial",sans-serif',
        h1: {
            fontSize: '2rem',
        },
        h2: {
            fontSize: '1.5rem',
        },
        h3: {
            fontSize: '1.25rem',
        },
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    padding: 0,
                    '& .MuiAutocomplete-input': {
                        padding: '0 10px',
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                html,
                body {
                    width: 100%;
                    height: 100%;
                    background-color: #f3f2f1;
                    height: 100vh;
                    height: -webkit-fill-available;
                }
                #root {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    height: 100vh;
                    height: -webkit-fill-available;
                }
                header {
                    flex: 0;
                }
                main {
                    flex: 1;
                }
                footer { 
                    flex: 0;
                }
            `,
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.87)',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    '&.MuiTypography-gutterBottom': {
                        marginBottom: '1rem',
                    },
                },
            },
        },
    },
});
